// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";

// If you want to add something do it here
@import "custom";
// @import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss"

@import "../../node_modules/react-modal-video/scss/modal-video.scss";

@import "semantic.css";

.c-app.c-dark-theme {
  .btn-success.btn-pill {
    border: 0 !important;
  }

  .btn.btn-success {
    padding: 1rem 2rem;
  }
}
