html {
  font-size: 100%;
  line-height: 1.6rem;
  font-family: Aeonik, "Montserrat", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  body {
    font-family: Aeonik, "Montserrat", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji" !important;
  }
  scroll-behavior: smooth;
}

iframe {
  width: 100% !important;
  height: 100% !important;
}

.c-dark-theme .btn-success {
  background-color: #129f53;
}

.grid {
  margin: 0 240px;
}

.nav_underlined {
  border: 2px solid #4fb978;
  margin-top: -1px;
  margin-top: -4px;
}

// .grid-page {
//   margin-left: 255px;
// }
// GO TO TOP
.go-to-top {
  opacity: 0.38;
  color: #fff;
  display: flex;
  transform: rotate(-90deg);
  position: fixed;
  bottom: 8%;
  right: -2.75rem;
  z-index: 1030;
  cursor: pointer;
  .icon {
    margin-left: 1rem;
  }
}

.modal.show .modal-dialog {
  display: flex;
  justify-content: center;
}

// END GO TO TOP
// END GRID
// DASHBOARD
// HEADER
.nav__bars-btn {
  width: 24px;
  height: 24px;
  display: none;
}

.nav__overlay {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 700;
}

.navbar__mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
  width: 320px;
  max-width: 100%;
  background: #138c4b;
  z-index: 999;
  transform: translateX(100%);
  transition: transform ease-in 0.3s;
  animation: fadeIn ease-in 0.3s;
  opacity: 0;
}

.nav__mobile {
  height: 72px;
  align-items: center;
  position: fixed;
  background-color: #050915;
  z-index: 900;
  width: 100%;
  justify-content: space-between;
  display: none;
}

.nav__input {
  display: none;
}

.nav__mobile-logo {
  width: 94px;
  height: 19px;
}

.navbar__mobile-logo {
  position: fixed;
  bottom: 30px;
  width: 132px;
  height: 27px;
  margin-left: 23px;
}

.navbar__mobile-list {
  margin-left: 15px;
}

.navbar__mobile-link {
  text-decoration: none;
  display: block;
  margin-top: 28px;
  font-weight: 700;
  width: fit-content;
  .nav_underlined {
    margin-top: 2px;
  }
  transition: 0.3s;
}

.nav__mobile-close {
  width: 32.5px;
  height: 32.5px;
  border-radius: 50%;
  display: flex;
  position: absolute;
  top: calc(10% - 50px);
  right: 1rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#mainNav {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #050915;
}

#mainNav .navbar-toggler {
  padding: 0.75rem;
  font-size: 0.75rem;
  color: #fff;
  text-transform: uppercase;
  background-color: #fed136;
  border: 0;
}

#mainNav .navbar-brand {
  color: #fed136;
  font-weight: 700;
  letter-spacing: 0.0625em;
  text-transform: uppercase;
}

#mainNav .navbar-brand img {
  height: 1.5rem;
}

#mainNav .navbar-nav .nav-item .nav-link {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.87);
  letter-spacing: 0.015625rem;
}

.nav_underlined {
  display: none;
}

.navbar__link.active ~ .nav_underlined {
  display: block;
}

.navbar__mobile-link.active .nav_underlined {
  display: block;
}

// #mainNav .navbar-nav .nav-item .nav-link.active,
// #mainNav .navbar-nav .nav-item .nav-link:hover {
//   color: #4fb978;
// }
.video_header {
  position: relative;
  background: linear-gradient(180deg, rgba(5, 9, 21, 0) 92.49%, #050915 100%);
}

header.masthead {
  position: absolute;
  top: 287px;
  color: #fff;
}

header.masthead .masthead-sticky {
  width: 20.625rem;
  height: 0px;
  left: 15.9375rem;
  top: 17.9375rem;
  margin-bottom: 2rem;
  border: 2px solid rgba(255, 255, 255, 0.87);
  opacity: 0.87;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  background: white;
}

header.masthead .masthead-subheading {
  font-size: 1.42rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  opacity: 0.87;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  width: 18%;
}

header.masthead .masthead-subheading:nth-child(3) {
  margin-bottom: 25px;
}

header.masthead .masthead-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 4.25rem;
  line-height: 130%;
  opacity: 0.87;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}

// HEADER
.form__field-contact:-webkit-autofill,
.form__field-contact:-webkit-autofill:hover,
.form__field-contact:-webkit-autofill:focus,
.form__field-contact:-webkit-autofill,
.form__field-contact:-webkit-autofill:hover,
.form__field-contact:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)
    ),
    #161925;
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #161925 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.language {
  width: 98px;
  height: 32px;
  background: rgba(19, 140, 75, 0.2);
  border-radius: 23px;
  .language__toggle {
    box-shadow: none;
    color: #4fb978;
    display: flex;
    justify-content: space-between;
    width: 92px;
    height: 32px;
    .text {
      // height: 30px;
      // line-height: 22px;
      margin: auto;
    }
  }
  .language__toggle:hover {
    color: #4fb978;
  }
  .language__toggle::after {
    display: none;
  }
}

.btn_wwr {
  font-family: Aeonik, "Montserrat", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-weight: 700;
}

.btn__learnmore {
  font-family: Aeonik, "Montserrat", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-weight: 700;
  margin-top: 38px;
}

// END HEADER
// SECTION
// .mobile-vectorImage {
//   background-image: url("../assets/images/Vector.png");
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: 100% 992px;
//   display: none !important;
//   width: 100%;
//   height: 100%;
// }
// .homepage-page {
//   display: block;
// }
.page-section {
  margin: 3rem 0;
}
.page-section-character {
  margin: 3rem 0;
}
.page-section-designai {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)
    ),
    #050915;
}

// END SECTION
// ABOUT US
.aboutus {
  min-height: 960px;
  .aboutus__title {
    font-size: 50px;
    line-height: 130%;
    font-weight: 700;
    opacity: 0.87;
  }
  .aboutus__heading {
    width: 100%;
    margin-top: 2rem;
    line-height: 2rem;
    font-size: 1.5rem;
    font-weight: 700;
    opacity: 0.6;
  }
  .aboutus__content {
    width: 105%;
    margin: 2rem 0;
    line-height: 1.5rem;
    font-size: 1.14rem;
    font-weight: 400;
    opacity: 0.6;
  }
  .aboutus__btn-contact {
    text-transform: uppercase;
    .aboutus__btn-contact-content {
      font-weight: 700;
      font-size: 0.8rem;
      line-height: 1rem;
      color: #28ad61;
    }
    .aboutus__btn-contact-content i {
      margin-left: 8px;
    }
  }
  .aboutus__btn-contact:hover {
    color: #28ad61 !important;
  }
  .aboutus_information {
    height: 729px;
    .aboutus_wrap {
      width: 450px;
    }
  }
}

.aboutus_wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.aboutus_sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  padding: 0 15px;
}

.aboutus_image {
  // text-align: right;
  // background-image: url("../assets/images/aboutus.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center center;
  text-align: right;
  position: relative;
  .aboutus_image__img {
    border-radius: 104px;
    // background: linear-gradient( 0deg, rgba(0, 59, 148, 0.12), rgba(0, 59, 148, 0.12));
  }
  .aboutus_image__svg {
    position: absolute;
    left: -11rem;
    bottom: -13rem;
  }
}

.ourvision_part-right {
  background: rgba(255, 255, 255, 0.07);
}

// END ABOUT US
// CHARACTER
.character {
  position: relative;
  z-index: 20;
  .image {
    width: 100%;
    border-radius: 0 8rem 0 0;
    margin: 14.75rem 0;
    margin-left: -15px;
  }
  .description {
    margin-left: 7.5rem;
    margin-top: 18.375rem;
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 130%;
      opacity: 0.87;
    }
    .content {
      margin-top: 2rem;
      font-style: normal;
      font-weight: normal;
      font-size: 1.14rem;
      line-height: 24px;
      letter-spacing: 0.15px;
      opacity: 0.6;
      width: 68%;
    }
    .about_us {
      font-weight: 700;
      text-transform: uppercase;
      color: #28ad61;
    }
  }
  .vectorImage {
    position: absolute;
    right: -14px;
    top: 0;
    opacity: 0.3;
    z-index: -1;
    // background-image: url(../assets/images/Vector.png);
    // height: 100%;
    // width: 100%;
    // background-attachment: fixed;
    // background-position: right;
    // background-repeat: no-repeat;
  }
}

// END CHARACTER
// DESIGN AI
.design_ai {
  // background: linear-gradient(
  //     0deg,
  //     rgba(255, 255, 255, 0.05),
  //     rgba(255, 255, 255, 0.05)
  //   ),
  //   #050915;
  // height: 906px;
  // position: relative;
  z-index: 200;
  .description {
    .header {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      /* identical to box height, or 30px */
      margin-top: 6.1875rem;
      /* Primary/Primary 300 */
      color: #70c58f;
    }
    .title {
      font-weight: 700;
      font-size: 50px;
      line-height: 130%;
      text-align: center;
      color: rgba(255, 255, 255, 0.87);
    }
    .content {
      margin-top: 2rem;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.15px;
      color: rgba(255, 255, 255, 0.6);
    }
    .work {
      color: #28ad61;
      font-weight: 700;
    }
    .work i {
      margin-left: 8px;
    }
  }
  .parent-image {
    margin-top: 14%;
    position: relative;
    height: 481px;
    .image {
      position: absolute;
      bottom: -13.4rem;
      width: 100%;
    }
  }
}

// END DESIGN AI
// MAGNIFICENT PRODUCT
.magnificent {
  margin: 3rem 0;
  padding-top: 239px;
  .title {
    width: 48%;
    font-weight: 700;
    font-size: 4.125rem;
    line-height: 5rem;
    color: rgba(255, 255, 255, 0.87);
  }
  .magnificent__content {
    margin: 2.5rem 0;
    .subTitle {
      font-weight: 400;
      font-size: 1.14rem;
      line-height: 1.5rem;
      color: rgba(255, 255, 255, 0.6);
    }
    .viewAll {
      text-align: right;
      .viewAll__link {
        font-weight: 700;
        color: #28ad61;
        text-transform: uppercase;
      }
      .viewAll__link i {
        margin-left: 8px;
      }
    }
  }
}

// END MAGNIFICENT PRODUCT
// CONTACT
.contact {
  padding-top: 9rem;
  padding-bottom: 11rem;
  height: 100%;
  .contact-left {
    width: 92%;
    .contact-left-title {
      font-size: 1.5rem;
      color: #4fb978;
      font-weight: 700;
    }
    .contact-title-left__subtitle_content {
      line-height: initial !important;
      font-weight: 700;
    }
    .contact-title-left__subtitle {
      font-size: 3.57rem;
      line-height: 6rem;
      color: rgba(255, 255, 255, 0.87);
      font-weight: 700;
      margin-top: 1.5rem;
      width: 20rem;
    }
    .contact-title-left__content {
      margin-top: 1.5rem;
    }
    .contact-title-left__content {
      p {
        font-size: 1.0774rem;
        line-height: 1.8rem;
        color: rgba(255, 255, 255, 0.87);
        font-weight: 400;
      }
    }
  }
  .formSubmitting {
    margin-bottom: 0;
    float: right;
  }
  .notification {
    color: #ffffff;
    font-size: large;
    font-weight: 400;
    margin-top: 18%;
    border-radius: 16px;
    letter-spacing: 0.15px;
  }
  .btn__submitting {
    height: 70%;
    margin-top: 10px;
  }
  .title__star {
    vertical-align: text-top;
  }
  .contact_btn__submit {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.4rem;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.87);
    background-color: #1f9e57 !important;
    border-color: #1f9e57 !important;
    font-family: Aeonik, "Montserrat", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  }
  .contact_btn__submit:hover {
    border-color: #1f9e57 !important;
  }
  .label-checkbox {
    color: #ffffff !important;
  }
  .c-dark-theme
    .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='#050915' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") !important;
  }
}

.contact1 {
  padding-top: 9rem;
  padding-bottom: 11rem;
  height: 100%;
  .contact-left {
    .contact-left-title {
      font-size: 1.5rem;
      color: #4fb978;
      font-weight: 700;
    }
    .contact-title-left__subtitle_content {
      font-weight: 700;
      font-size: 50px;
      line-height: 130%;
      color: rgba(255, 255, 255, 0.87);
    }
    .contact-title-left__subtitle {
      font-size: 3.57rem;
      line-height: 6rem;
      color: rgba(255, 255, 255, 0.87);
      font-weight: 700;
      margin-top: 1.5rem;
      // width: 26rem;
      width: 89%;
    }
    .content {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      opacity: 0.87;
      padding-right: 8rem;
    }
    .contact-title-left__content {
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      color: rgba(255, 255, 255, 0.87);
      opacity: 0.87;
      flex: none;
      order: 0;
      align-self: flex-start;
      flex-grow: 0;
      padding-top: 2.5rem;
      //   width: 250px;
    }
    .garenal {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: rgba(255, 255, 255, 0.87);
      align-self: flex-start;
      flex-grow: 0;
      margin-top: 16px;
    }
    .email_contact {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #4fb978;
      order: 1;
      align-self: flex-start;
      flex-grow: 0;
      margin-top: 4px;
    }
    .contact-title-left__content p {
      margin-bottom: 6px;
    }
  }
  .formSubmitting {
    margin-top: 2rem;
    margin-bottom: 0;
    height: 100%;
    text-align: left;
  }
  .notification {
    color: #ffffff;
    font-size: large;
    font-weight: 400;
    margin-top: 18%;
    border-radius: 16px;
    letter-spacing: 0.15px;
  }
  .btn__submitting {
    height: 70%;
    margin-top: 10px;
  }
  .title__star {
    vertical-align: text-top;
  }
  .contact_btn__submit {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.4rem;
    font-weight: 700;
    opacity: 0.87;
    background-color: #1f9e57 !important;
    border-color: #1f9e57 !important;
    font-weight: 700;
    letter-spacing: 0.25px;
    line-height: 16px;
  }
  .contact_btn__submit:hover {
    border-color: #1f9e57 !important;
  }
  .label-checkbox {
    color: #ffffff !important;
  }
  .c-dark-theme
    .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='#050915' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") !important;
  }
}

// END CONTACT
// TRUSTED BY
.trusted {
  padding-top: 12rem;
  .trustedBy_title {
    .trusted_title {
      font-size: 3.6rem;
      line-height: 4.6rem;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.87);
      margin-bottom: 2rem;
      font-family: Aeonik, "Montserrat", -apple-system, BlinkMacSystemFont,
        "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji" !important;
    }
  }
  .trusted_left {
    font-size: 1.2rem;
    font-weight: 400px;
    line-height: 1.8rem;
    color: rgba(255, 255, 255, 0.6);
  }
  .trusted_right {
    .trusted_button__btn {
      color: rgba(255, 255, 255, 0.87);
      text-transform: uppercase;
      font-size: 1rem;
      padding: 20px 24px;
      border: 2px solid #1f9e57;
      box-sizing: border-box;
      border-radius: 75px;
      font-weight: 700;
      font-family: Aeonik, "Montserrat", -apple-system, BlinkMacSystemFont,
        "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji" !important;
    }
  }
}

// FEEDBACK
.feedBack {
  padding: 8.5rem 0;
  position: relative;
  .feedBackLeft {
    .feedBackLeft__name {
      margin-top: 1.7rem;
      .feedBackLeft__name_Author {
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 3.1rem;
        color: rgba(255, 255, 255, 0.87);
        font-family: Aeonik, "Montserrat", -apple-system, BlinkMacSystemFont,
          "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
          "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
          "Noto Color Emoji" !important;
      }
    }
    .feedBackLeft__sologan {
      max-width: 386px;
      .feedBackLeft__sologan__content {
        font-size: 1.5rem;
        line-height: 2.2rem;
        font-weight: 700;
        color: #4fb978;
      }
    }
    .feedBackLeft__introduce {
      margin-top: 1.2rem;
      .feedBackLeft__introduce__content {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: rgba(255, 255, 255, 0.87);
      }
    }
    .feedBackLeft__footer {
      margin-top: 1.7rem;
    }
  }
  .feedBackRight__sticky {
    width: 445px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    height: 0;
    transform: rotate(90deg);
    position: absolute;
    left: -14rem;
    top: 20rem;
  }
  .feedBackRight {
    padding-top: 2rem;
    .feedBackRight__item {
      .feedBackRight__item-wrap {
        display: flex;
        align-items: center;
        margin-top: 4rem;
        justify-content: center;
      }
      .feedBackRight__item-image {
        margin-top: 2rem;
      }
      .feedBackRight__item-wrap:nth-child(6) {
        padding: 0;
      }
    }
    // }
  }
}

.contact {
  padding-bottom: 3rem;
  padding-top: 3rem;
}

// END FEEDBACK
// END TRUSTED BY
// RESEARCH
.page-section-research {
  background: linear-gradient(
      0deg,
      rgba(0, 59, 148, 0.12),
      rgba(0, 59, 148, 0.12)
    ),
    linear-gradient(90deg, #121728 20.4%, rgba(18, 23, 40, 0) 68.85%),
    url(/static/media/athena.7508a8a8.png), #121728;
  background-repeat: no-repeat;
}

.research {
  padding: 7.514285714285714rem 0;
  margin-top: 14.6875rem;
  .description {
    .title {
      font-weight: 700;
      font-size: 50px;
      line-height: 130%;
      color: rgba(255, 255, 255, 0.87);
    }
    .content {
      font-size: 16px;
      line-height: 1.5rem;
      letter-spacing: 0.15px;
      color: rgba(255, 255, 255, 0.6);
      width: 50%;
      margin-top: 2%;
    }
  }
}

// END RESEARCH
// FOOTER
.footer {
  background: #050915 !important;
  border-right-width: 87px;
  border-left-width: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 100%;
  .nav {
    .item {
      .link {
        color: rgba(255, 255, 255, 0.6);
        font-size: 1.14rem;
      }
    }
  }
  .logo {
    margin: auto;
    margin-left: -50px;
    .img {
      vertical-align: middle;
      height: 1.3125rem;
    }
  }
  .copyright {
    margin: auto;
    text-align: right;
    color: rgba(255, 255, 255, 0.6);
  }
}

// END FOOTER
// END DASHBOARD
// ABOUT US PAGE
header.header-aboutus {
  padding-top: 15.5rem;
  padding-bottom: 11.5rem;
  text-align: center;
  color: #fff;
  background-image: url("../assets/images/image_aboutus.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  .header-aboutus-sticky {
    width: 76px;
    border: 2px solid rgba(255, 255, 255, 0.87);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    display: inline-block;
  }
  .header-aboutus-heading {
    margin-top: 1.7rem;
    margin-bottom: 1.7rem;
    font-size: 4.85rem;
    line-height: 6.3rem;
    font-weight: 700;
    font-family: Aeonik, "Montserrat", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji" !important;
  }
  .header-aboutus-content span {
    font-size: 1.14rem;
    line-height: 1.7rem;
    color: rgba(255, 255, 255, 0.6);
    font-family: Aeonik, "Montserrat", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji" !important;
  }
  .header-aboutus-content i {
    color: rgba(255, 255, 255, 0.38);
    font-size: 1.14rem;
    margin: 0 8px;
  }
}

// WHAT BAI
.whatbai {
  padding-top: 9rem;
  text-align: center;
  padding-bottom: 6.1rem;
  .whatbai-title h2 {
    font-size: 3.57rem;
    line-height: 4.64rem;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.87);
    font-family: Aeonik, "Montserrat", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji" !important;
  }
  .whatbai-quote {
    margin: 0 425px;
    margin-top: 2.3rem;
    .whatbai-quote-content {
      font-size: 1.14rem;
      font-weight: 400;
      line-height: 1.7rem;
      color: rgba(255, 255, 255, 0.87);
      opacity: 0.87;
    }
  }
  .whatbai-ceo {
    margin-top: 10.35rem;
    display: flex;
    text-align: left;
    justify-content: center;
    .whatbai-ceo-left {
      width: 32rem;
      margin-right: 150px;
      // .whatbai-ceo-left-image {
      //   background-image: url("../assets/images/florian.png");
      //   height: 36.2rem;
      //   border-radius: 16px;
      //   width: 100%;
      //   background-repeat: no-repeat;
      //   background-attachment: scroll;
      //   background-position: center top;
      //   background-size: cover;
      // }
      .whatbai-ceo-left-infomation {
        margin-top: 2.1rem;
        .whatbai-ceo-left-infomation__name {
          font-size: 2.42rem;
          font-weight: 700;
          line-height: 3.15rem;
          color: rgba(255, 255, 255, 0.87);
        }
        .whatbai-ceo-left-infomation__position {
          margin: 1.1rem 0;
          font-size: 1.14rem;
          line-height: 1.7rem;
          opacity: 0.6;
        }
        .whatbai-ceo-left-infomation__content {
          font-size: 1.14rem;
          line-height: 1.7rem;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.87);
          opacity: 0.87;
        }
        .whatbai-ceo-left-infomation__link {
          margin-top: 1.1rem;
          a {
            color: #4fb978;
            font-size: 1.4rem;
            line-height: 1.4rem;
            font-weight: 700;
          }
        }
      }
    }
    .whatbai-ceo-right {
      width: 450px;
      .whatbai-ceo-right-infomation {
        margin-top: 2.1rem;
        .whatbai-ceo-right-infomation__name {
          font-size: 2.42rem;
          font-weight: 700;
          line-height: 3.15rem;
          color: rgba(255, 255, 255, 0.87);
        }
        .whatbai-ceo-right-infomation__position {
          margin: 1.1rem 0;
          font-size: 1.14rem;
          line-height: 1.7rem;
          color: rgba(255, 255, 255, 0.6);
          opacity: 0.6;
        }
        .whatbai-ceo-right-infomation__content {
          font-size: 1.14rem;
          line-height: 1.7rem;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.87);
          opacity: 0.87;
        }
        .whatbai-ceo-right-infomation__link {
          margin-top: 1.1rem;
          a {
            color: #4fb978;
            font-size: 1.4rem;
            line-height: 1.4rem;
            font-weight: 700;
          }
        }
      }
    }
  }
}

// END WHAT BAI
// OUR VISION
.ourvision {
  margin-top: 10.3rem;
  margin-bottom: 16rem;
  text-align: center;
  .ourvision-title {
    font-size: 3.6rem;
    line-height: 4.6rem;
    font-weight: 700;
    opacity: 0.87;
  }
  .ourvision-quote {
    margin-top: 1rem;
    margin-left: 625px;
    margin-right: 625px;
    p {
      font-size: 1.14rem;
      font-weight: 400;
      line-height: 1.7rem;
    }
  }
  .ourvision-questions {
    margin-top: 100px;
  }
  .ourvision-questions__list {
    text-align: left;
    border-bottom: 0;
    padding-top: 72px;
    padding-bottom: 72px;
    .ourvision-questions__item {
      padding-left: 255px !important;
      position: relative;
      .ourvision-questions__item-link {
        font-size: 1.4rem;
        line-height: 150%;
        padding: 16px 0;
        position: relative;
        display: block;
        text-decoration: none;
        color: rgba(255, 255, 255, 0.6);
        font-weight: 700;
        i {
          display: none;
          margin-left: 2.1rem;
        }
      }
    }
    .active.ourvision-questions__item-link {
      color: #70c58f !important;
      i {
        display: inline-block !important;
      }
    }
    .ourvision-questions__item::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0px;
      right: 0;
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-image-source: linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 0.082),
        #ffffff00
      );
      border-image-slice: 1;
      border-left: 0;
    }
    .ourvision-questions__item:last-child::before {
      position: absolute;
      content: "";
      bottom: 0;
      border-bottom: 1px solid;
      border-image-source: linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 0.082),
        #ffffff00
      );
      border-image-slice: 1;
      border-left: 0;
    }
  }
  .ourvision_tabpane {
    padding-left: 150px;
    padding-right: 130px;
    padding-bottom: 80px;
    text-align: left;
    position: relative;
    .ourvision_tabpane-title {
      margin-top: 111px;
      font-size: 2.42rem;
      line-height: 3.15rem;
      font-weight: 700;
      opacity: 0.87;
    }
    .ourvision_tabpane-content {
      margin: 2.28rem 0;
      font-size: 1.14rem;
      line-height: 1.7rem;
      font-weight: 400;
      opacity: 0.6;
    }
    .ourvision_tabpane-content::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .ourvision_tabpane-content {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
    .ourvision_tabpane-contact-link {
      font-size: 1.14rem;
      line-height: 1.1rem;
      font-weight: 700;
      color: #28ad61;
      text-decoration: none;
      i {
        margin-left: 8px;
      }
    }
    .ourvision_tabpane-img-elip {
      position: absolute;
      top: -104px;
      left: -254px;
      mix-blend-mode: screen;
      opacity: 0.5;
    }
    .ourvision_tabpane-img-B {
      position: absolute;
      top: -45px;
      left: -180px;
    }
  }
  .ourvision_tabpane-control {
    display: none;
    margin-bottom: 40px;
    margin-top: 32px;
    padding: 0 1.5rem;
    .control-button {
      height: 32px;
      width: 32px;
      background: rgba(255, 255, 255, 0.38);
      opacity: 0.3;
      border-radius: 50%;
      font-size: 1.5rem;
      font-weight: 900;
      line-height: 32px;
      color: rgba(0, 0, 0, 1);
      padding: 0;
      border: none;
    }
    .control-button_left {
      margin-right: 1.5rem;
      margin-top: 1.5rem;
    }
    .control-button:focus {
      outline: none;
      background: rgba(255, 255, 255, 1);
    }
    .control-button:hover {
      background: rgba(255, 255, 255, 1);
      transition: ease-in 0.35;
    }
  }
}

// END OUR VISION
// ACTIVITY
.aboutus-activity {
  padding-top: 113px;
  text-align: center;
  padding-bottom: 155px;
  .aboutus-activity__title {
    margin: 0 377px;
    font-size: 3.58rem;
    line-height: 4.64rem;
    font-weight: 700;
    margin-bottom: 56px;
  }
  .aboutus-activity__quote {
    margin: 1.1rem 330px 4rem;
    p {
      font-size: 1.14rem;
      line-height: 1.7rem;
      font-weight: 400;
      opacity: 0.6;
    }
  }
}

.tab-content > .active {
  margin-top: 4rem;
}

.tab-content-left {
  .tab-content-left-title {
    margin: 0 234px;
    font-size: 1.7rem;
    line-height: 2.2rem;
    font-weight: 700;
    opacity: 0.85;
  }
  .tab-content-left-quote {
    margin: 0 340px;
    font-size: 1.14rem;
    line-height: 1.7rem;
    opacity: 0.6;
    p {
      margin-top: 1.7rem;
    }
  }
  .tab-content-left-img-list {
    margin-top: 5.7rem;
    margin-left: 100px;
    margin-right: 100px;
    .tab-content-left-img-item {
      background: linear-gradient(
        0deg,
        rgba(0, 59, 148, 0.12),
        rgba(0, 59, 148, 0.12)
      );
    }
  }
}

.tab-content-middle {
  .tab-content-middle-title {
    margin: 0 234px;
    font-size: 1.7rem;
    line-height: 2.2rem;
    font-weight: 700;
    opacity: 0.85;
  }
  .tab-content-middle-quote {
    margin: 0 340px;
    font-size: 1.14rem;
    line-height: 1.7rem;
    opacity: 0.6;
    p {
      margin-top: 1.7rem;
    }
  }
  .tab-content-middle-img-list {
    margin-top: 5.7rem;
    margin-left: 100px;
    margin-right: 100px;
    .tab-content-middle-img-item {
      background: linear-gradient(
        0deg,
        rgba(0, 59, 148, 0.12),
        rgba(0, 59, 148, 0.12)
      );
    }
  }
}

.tab-content-right {
  .tab-content-right-title {
    margin: 0 234px;
    font-size: 1.7rem;
    line-height: 2.2rem;
    font-weight: 700;
    opacity: 0.85;
  }
  .tab-content-right-quote {
    margin: 0 340px;
    font-size: 1.14rem;
    line-height: 1.7rem;
    opacity: 0.6;
    p {
      margin-top: 1.7rem;
    }
  }
  .tab-content-right-img-list {
    margin-top: 5.7rem;
    margin-left: 100px;
    margin-right: 100px;
    .tab-content-right-img-item {
      background: linear-gradient(
        0deg,
        rgba(0, 59, 148, 0.12),
        rgba(0, 59, 148, 0.12)
      );
    }
  }
}

.about-us__tab-list {
  padding: 0 300px !important;
  .about-us__tab-item {
    .about-us__tab-item__link {
      padding: 8px 28px !important;
      font-size: 1.14rem !important;
      line-height: 1.7rem !important;
      font-weight: 400 !important;
      color: rgba(255, 255, 255, 0.87) !important;
    }
  }
}

.aboutus-activity__tabs--mobile {
  display: none;
}

.c-dark-theme .nav-tabs .nav-link.active,
.c-dark-theme
  .nav-tabs
  .nav-item.show
  .nav-link
  ~ .about-us__tab-item-bar::before {
  color: #e1e1e1;
  background-color: transparent !important;
  border-color: none !important;
  border: none !important;
}

.c-dark-theme .nav-tabs .nav-link.active ~ .about-us__tab-item-bar::before {
  width: 100%;
}

.c-dark-theme .nav-tabs .nav-link:hover,
.c-dark-theme .nav-tabs .nav-link:focus {
  border: none !important;
}

.about-us__tab-item-bar {
  position: relative;
  display: block;
  width: 100%;
}

.about-us__tab-item-bar::before {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  background: #4fb978;
  left: 0;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.c-dark-theme .nav-tabs .nav-link.active,
.c-dark-theme .nav-tabs .nav-link:focus ~ .about-us__tab-item-bar::before {
  width: 100%;
}

.about-us__tab-item-bar--mobile {
  position: relative;
  display: block;
  width: 100%;
}

.about-us__tab-item-bar--mobile::before {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  background: #4fb978;
  left: 0;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.c-dark-theme
  .about-us__tab-list--mobile
  .about-us__tab-item__link--mobile.active,
.c-dark-theme
  .about-us__tab-list--mobile
  .about-us__tab-item__link--mobile:focus
  ~ .about-us__tab-item-bar--mobile::before {
  width: 100%;
}

.about-us__tab-item__link--mobile.active
  ~ .about-us__tab-item-bar--mobile::before {
  width: 100%;
}

// END ACTIVITY
// PRODUCT
.Product-top {
  margin: 105px 135px 104px 255px;
  .Product-top__title {
    font-size: 2.55rem;
    line-height: 3.7rem;
    font-weight: 700;
    margin-bottom: 2.85rem;
  }
  .Product-top__button {
    color: #ffffff;
    font-size: 14px;
    border: 2px solid #1f9e57;
    box-sizing: border-box;
    border-radius: 75px;
    padding: 20px 24px;
  }
}

.Product-top-img {
  background-image: url(../assets/images/intel-product-image-top.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}

.Product-bottom-img {
  background-image: url(../assets/images/intel-product-img-bottom.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}

.Product-bottom {
  margin: 104px 413px 105px 56px;
  .Product-bottom__title {
    font-size: 2.55rem;
    line-height: 3.7rem;
    font-weight: 700;
    margin-bottom: 2.85rem;
  }
  .Product-bottom__button {
    color: #ffffff;
    font-size: 14px;
    border: 2px solid #1f9e57;
    box-sizing: border-box;
    border-radius: 75px;
    padding: 20px 24px;
    width: 237px;
  }
}

// END PRODUCT
// END ABOUT US PAGE
// FORM CONTACT
.form__group-contact {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

.form__field-contact {
  width: 100%;
  border: 0;
  border-bottom: 1px solid gray !important;
  outline: 0;
  font-size: 1.3rem;
  color: white;
  padding: 7px 14px 7px 0;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)
    ),
    #050915 !important;
  transition: gray 0.2s;
  border-radius: 0 !important;
}

.form__contact-label {
  position: absolute;
  top: 26px;
  display: block;
  transition: 0.2s;
  font-size: 1.14rem;
  color: #ffffff;
  opacity: 0.6;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.form__field-contact:focus ~ .form__contact-label,
.form__field-contact:valid ~ .form__contact-label {
  outline: none !important;
  top: 0 !important;
  font-size: 1rem !important;
}

// textarea:focus ~ label, textarea:valid ~ label     {
//   top:0 !important;
//   font-size:1rem !important;
// }
.form__field-contact:focus {
  outline: none !important;
  box-shadow: none;
}

.form__field-contact:focus {
  ~ .form__contact-label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1.14rem;
    font-weight: 400;
    outline: none !important;
  }
  padding-bottom: 6px;
  font-weight: 400;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  box-shadow: none;
  outline: none !important;
}

.c-dark-theme .form-control:focus {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)
    ),
    #050915;
  box-shadow: none;
}

.custom-checkbox__content {
  font-style: italic;
  font-size: 14px;
  line-height: 1.4rem;
}

//ATHENA RESEARCH CENTENT
// HEADER
header.header-athena {
  padding-top: 14.875rem;
  padding-bottom: 11rem;
  text-align: center;
  color: #fff;
  background-image: url("../assets/images/header_athena.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  .sticky {
    width: 4.75rem;
    border: 2px solid rgba(255, 255, 255, 0.87);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    display: inline-block;
  }
  .heading {
    margin-top: 1.7rem;
    margin-bottom: 1.7rem;
    font-size: 4.85rem;
    line-height: 6.3rem;
    font-weight: 700;
    font-family: Aeonik, "Montserrat", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  }
  .content {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(255, 255, 255, 0.6);
    flex: none;
    order: 0;
    align-self: center;
    flex-grow: 0;
    margin: 8px 0px;
    i {
      color: rgba(255, 255, 255, 0.38);
      font-size: 1.14rem;
      margin: 0 8px;
    }
  }
  // .content
}

// END HEADER
// CONTENT
.athena-content {
  text-align: center;
  padding: 66px 495px;
  .summary {
    font-size: 16px;
    letter-spacing: 0.15px;
    opacity: 0.87;
  }
}

// END CONTENT
.popup-right {
  .popup-right__subtitle {
    margin: 14px 0 0 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.87);
  }
  .popup-right__intro {
    margin: 24px 0 0 24px;
    font-size: 16px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.87);
  }
}

// OUR FEATURED
.athena-ourfeatured {
  margin-bottom: 270px;
  .title {
    font-weight: 700;
    font-size: 50px;
    line-height: 130%;
    text-align: center;
    color: rgba(255, 255, 255, 0.87);
    margin-bottom: 4rem;
    margin-top: 7.5rem;
  }
  .product {
    border-radius: 16px;
    overflow: hidden;
    width: 450px;
    height: 339px;
    margin-bottom: 30px;
    position: relative;
    .product-wrapper {
      overflow: hidden;
      border-radius: 20px;
    }
    .product-img {
      width: 450px;
      height: 339px;
      transition: transform 0.2s;
    }
    .product-img:hover {
      transform: scale(1.1);
    }
    .video {
      min-width: 100% !important;
      min-height: 100% !important;
      width: 467px;
    }
    .content {
      position: absolute;
      left: 24px;
      bottom: 24px;
      width: 90%;
      // z-index: 1000;
      .magnificent-products-subcontent-icon-circle {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.38);
        opacity: 0.3;
        display: flex;
        margin-bottom: 16px;
        .magnificent-products-subcontent-icon-play {
          font-size: 18px;
          margin: auto;
        }
      }
      .icon-circle {
        width: 40px;
        height: 40px;
        opacity: 0.38;
        border-radius: 50%;
        text-align: center;
        i {
          line-height: 40px;
          font-size: 18px;
          color: white;
        }
      }
      .title-item {
        color: rgba(255, 255, 255, 0.945);
        text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
        opacity: 0.87;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        margin-top: 16px;
      }
    }
  }
}

.popup--Video {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.12)
    ),
    #050915 !important;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.3) !important;
  border-radius: 16px !important;
  flex-direction: row !important;
}

.modal__wrapper {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.12)
    ),
    #050915;
  .popup__button {
    display: flex;
    justify-content: center;
  }
}

.popup-modal {
  margin-top: 80px;
  display: flex;
  .button-mobile {
    display: none;
  }
  .popup-video {
    height: 398px !important;
    width: 100% !important;
    overflow: hidden;
    display: block;
    background: #000;
    border: none;
  }
  .button-next {
    position: absolute;
    right: -6%;
    top: 42%;
    cursor: pointer;
  }
  .button-prev {
    position: absolute;
    left: -6%;
    top: 42%;
    cursor: pointer;
  }
  .button-close {
    position: absolute;
    right: -62px;
    cursor: pointer;
  }
}

.popup-right {
  .title {
    font-weight: 700;
    font-size: 28px;
    line-height: 130%;
    /* or 44px */
    display: flex;
    align-items: flex-end;
    /* Dark/On Surface/High Emphasis */
    color: rgba(255, 255, 255, 0.87);
    /* Inside Auto Layout */
    flex: none;
    order: 0;
    align-self: center;
    flex-grow: 0;
    margin: 0px 24px;
    word-break: break-word;
  }
  .content {
    margin-top: 30px;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    letter-spacing: 0.5px;
    /* Dark/On Surface/High Emphasis */
    color: rgba(255, 255, 255, 0.87);
    opacity: 0.87;
    /* Inside Auto Layout */
    flex: none;
    order: 1;
    align-self: center;
    flex-grow: 0;
    margin: 24px 24px;
  }
}

// END OUR FEATURED
// WANNA TURNING
.section-Contact {
  background: linear-gradient(
      90deg,
      rgba(18, 23, 40, 0.57) 47.4%,
      rgba(18, 23, 40, 0) 83.85%
    ),
    url("../assets/images/athena/bg_wanna.png"), #121728;
}

.athena_wanna {
  padding: 13rem 0;
  margin-top: -9.25rem;
  position: relative;
  background-blend-mode: multiply, normal;
  .description {
    position: absolute;
    top: 4.75rem;
    // .title {
    //   font-weight: 700;
    //   font-size: 50px;
    //   line-height: 130%;
    //   color: rgba(255, 255, 255, 0.87);
    // }
    .content {
      font-weight: 700;
      font-size: 50px;
      line-height: 130%;
      color: rgba(255, 255, 255, 0.87);
      width: 68%;
    }
    .athenan_wanna__button {
      font-weight: 700;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.25px;
      opacity: 0.87;
      background-color: #2eb85c;
      border-color: #2eb85c;
      padding: 16px 24px;
    }
  }
}

// END ATHENA RESEARCH CENTENT
// END FORM CONTACT
// AI DESIGN
header.header-ai-design {
  padding-top: 15.5rem;
  padding-bottom: 11.5rem;
  text-align: center;
  color: #fff;
  background-image: url("../assets/images/AI_background.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  .header-ai-design-sticky {
    width: 76px;
    border: 2px solid rgba(255, 255, 255, 0.87);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    display: inline-block;
  }
  .header-ai-design-heading {
    margin-top: 1.7rem;
    margin-bottom: 1.7rem;
    font-size: 4.85rem;
    line-height: 6.3rem;
    font-weight: 700;
    font-family: Aeonik, "Montserrat", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji" !important;
  }
  .header-ai-design-content span {
    font-size: 1.14rem;
    line-height: 1.7rem;
    color: rgba(255, 255, 255, 0.6);
  }
  .header-ai-design-content i {
    color: rgba(255, 255, 255, 0.38);
    font-size: 1.14rem;
    margin: 0 8px;
  }
}

.information {
  padding: 111px 0;
  margin: 0 409px;
  text-align: center;
  .information-sologan {
    font-size: 3.57rem;
    font-weight: 700;
    line-height: 4.64rem;
  }
  .information-quote {
    margin-top: 2.28rem;
    font-size: 1.2rem;
    line-height: 1.7rem;
    font-weight: 400;
    opacity: 0.87;
  }
}

.imgage-left {
  left: 12px;
}

.image-right {
  left: -105px;
}

.section-idea1 {
  background-image: url(../assets/images/backgroundImageAIDesigntop.png);
  background-repeat: no-repeat;
  background-size: 1200px 1500px;
  background-position: bottom left;
  .idea {
    padding-bottom: 0;
  }
  //transform: rotate(180deg);
}

.section-desingai {
  background-image: url(../assets/images/backgroundImageBottomAIDessign.png);
  background-repeat: no-repeat;
  background-size: 700px 2710px;
  background-position: 100% 130%;
}

.idea {
  padding-top: 160px;
  .idea-top {
    z-index: 300 !important;
    height: 606px;
    display: flex;
  }
  .idea-bottom {
    position: relative;
    //height: 606px;
    .idea-bottom-image {
      position: absolute;
    }
  }
  .designai {
    margin: auto;
    .designai-main {
      padding: 40px;
      background: #ffffff;
      box-shadow: 0px 24px 72px rgba(0, 0, 0, 0.3);
      .designai-index {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 2.1rem;
        text-transform: uppercase;
        color: #9f9f9f;
      }
      .designai-title {
        font-size: 2.3rem;
        line-height: 3.15rem;
        font-weight: 700;
        color: #050915;
        margin: 24px 0 !important;
      }
      .designai-content {
        font-size: 1.14rem;
        line-height: 1.7rem;
        color: #050915;
        font-weight: 400;
        margin-bottom: 40px;
      }
      .designai-button {
        background: #1f9e57 !important;
        border-radius: 75px !important;
        color: #ffffff !important;
        padding: 20px 24px;
        text-decoration: none;
        font-weight: 700;
        font-size: 1.14rem;
        line-height: 1.1rem;
        border: 0;
        text-transform: uppercase;
        font-family: Aeonik, "Montserrat", -apple-system, BlinkMacSystemFont,
          "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
          "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
          "Noto Color Emoji";
        .designai-button-content-index {
          opacity: 0.6;
        }
      }
    }
  }
}

.section-idea5 {
  padding-bottom: 160px;
}

// END AI DESIGN
// PRODUCT
header.header-product {
  padding-top: 15.5rem;
  padding-bottom: 11.5rem;
  text-align: center;
  color: #fff;
  background-image: url("../assets/images/product/product_background.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  .header-product-sticky {
    width: 76px;
    border: 2px solid rgba(255, 255, 255, 0.87);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    display: inline-block;
  }
  .header-product-heading {
    margin-top: 1.7rem;
    margin-bottom: 1.7rem;
    font-size: 4.85rem;
    line-height: 6.3rem;
    font-weight: 700;
    font-family: Aeonik, "Montserrat", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  }
  .header-product-content span {
    font-size: 1.14rem;
    line-height: 1.7rem;
    color: rgba(255, 255, 255, 0.6);
  }
  .header-product-content i {
    color: rgba(255, 255, 255, 0.38);
    font-size: 1.14rem;
    margin: 0 8px;
  }
}

.subheader-product {
  margin: 0 240px;
  padding: 68px 0;
  text-align: center;
  p {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}

.section-DemoProduct {
  background-image: url(../assets/images/product/demo_image.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  .product-demo {
    padding: 143px 0;
    .product-demo-title {
      font-weight: 700;
      font-size: 3.57rem;
      line-height: 4.64rem;
    }
    .product-demo__button {
      margin-top: 2.28rem;
      font-size: 1.14rem;
      font-weight: 700;
      line-height: 1.1rem;
    }
  }
}

.magnificent-products {
  padding-top: 120px;
  padding-bottom: 259px;
  text-align: center;
  .magnificent-products-title {
    margin: 0 400px;
    font-size: 3.57rem;
    line-height: 4.64rem;
    font-weight: 700;
  }
  .magnificent-products-content {
    padding-top: 4.57rem;
  }
}

.magnificent-products-subcontent-list:nth-child(2) {
  margin-bottom: 30px;
}

.magnificent-products-subcontent-list:nth-child(1) {
  margin-bottom: 30px;
}

.magnificent-products-subcontent-list {
  position: relative;
  border-radius: 16px;
  .magnificent-products-subcontent-list__wrap {
    border-radius: 20px;
    overflow: hidden;
  }
  .magnificent-products-subcontent {
    position: absolute;
    left: 40px;
    bottom: 20px;
    text-align: left;
    .magnificent-products-subcontent-icon-circle {
      width: 40px;
      height: 40px;
      background: rgba(255, 255, 255, 0.38);
      border-radius: 50%;
      opacity: 0.3;
      display: flex;
      margin-bottom: 16px;
      .magnificent-products-subcontent-icon-play {
        font-size: 18px;
        margin: auto;
      }
      .magnificent-icon-new_tab {
        margin: auto;
        color: rgba(255, 255, 255, 0.38);
      }
    }
    .magnificent-products-subcontent-title {
      font-weight: 700;
      font-size: 1.3rem;
      line-height: 2.1rem;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    }
  }
  .magnificent-products-subcontennt-background {
    border-radius: 16px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transition: transform 0.2s;
  }
  .magnificent-products-subcontennt-background:hover {
    transform: scale(1.1);
  }
}

// END PRODUCT
// CONTACT US
header.header-contact-us {
  padding-top: 14.875rem;
  padding-bottom: 11rem;
  text-align: center;
  color: #fff;
  background-image: url("../assets/images/contactus/header.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  .sticky {
    width: 4.75rem;
    border: 2px solid rgba(255, 255, 255, 0.87);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    display: inline-block;
  }
  .heading {
    font-weight: 700;
    font-size: 68px;
    line-height: 130%;
    text-align: center;
    color: rgba(255, 255, 255, 0.87);
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    margin-bottom: 2rem;
    margin-top: 2rem;
    font-family: Aeonik, "Montserrat", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji" !important;
  }
  .content {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(255, 255, 255, 0.6);
    flex: none;
    order: 0;
    align-self: center;
    flex-grow: 0;
    margin: 8px 0px;
    i {
      color: rgba(255, 255, 255, 0.37);
      font-size: 1.14rem;
      margin: 0 8px;
    }
  }
  // .content
}

header.header-policy {
  padding-top: 14.875rem;
  padding-bottom: 11rem;
  color: #fff;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.07),
      rgba(255, 255, 255, 0.07)
    ),
    #050915;
  opacity: 0.87;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  .header-content {
    width: 570px;
    margin-top: -80px;
    .title-cookies-policy {
      font-size: 68px;
      line-height: 130%;
      opacity: 0.87;
    }
    .header-policy-navigation {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      opacity: 0.6;
      .home-link {
        color: #4fb978;
      }
    }
    .header-policy-content {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      opacity: 0.87;
      margin-top: 16px;
    }
  }
}

.policy {
  display: flex;
  flex-direction: row;
  margin: 48px 0 140px 0;
  .policy-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .policy-menu-title {
      font-size: 20px;
      line-height: 150%;
      text-transform: uppercase;
      opacity: 0.6;
      font-weight: bold;
      font-size: 20px;
      line-height: 150%;
    }
    .policy-menu-ul {
      list-style: none;
      padding-left: 0;
    }
    .policy-menu-tab-menu {
      font-weight: bold;
      font-size: 24px;
      line-height: 130%;
      display: flex;
      align-items: center;
      letter-spacing: 0.18px;
      color: #4fb978;
      padding: 10px 10px 10px 15px;
      margin: 20px 0;
      .policy_underlined {
        border: 2px solid #4fb978;
        margin-top: -1px;
        margin-top: -4px;
      }
    }
    .policy-menu-tab-menu:focus {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.05),
          rgba(255, 255, 255, 0.05)
        ),
        #050915;
    }
  }
  .policy-content {
    overflow-y: scroll;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    height: 792px;
    .policy-conetent-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .policy-content-title {
        font-weight: bold;
        font-size: 34px;
        line-height: 130%;
      }
      .policy-content-content {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin-top: 32px;
        margin-bottom: 48px;
        opacity: 0.87;
        letter-spacing: 0.5px;
      }
    }
  }
  .policy-content::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .policy-content {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

// END CONTACT US
//IMPRINT
header.header-imprint {
  padding-top: 30%;
  text-align: center;
  background-image: url("../assets/images/imprint/headerpng.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  display: flex;
  height: 637px;
  .header-imprint-content {
    position: relative;
    width: 100%;
    height: 100px;
    .header-imprint-circle {
      line-height: 100px;
      position: absolute;
      width: 100%;
      height: 110px;
      background-image: radial-gradient(
        circle 107px at 50% 0,
        transparent 700px,
        #0a0b18 50px
      );
      bottom: -2px;
      left: 0;
    }
  }
  .header-imprint-circle-background {
    height: 207px;
    width: 207px;
    position: absolute;
    border-radius: 50%;
    top: -105px;
    left: calc(50% - 103px);
    background: #138c4b;
    display: flex;
    .header-imprint-circle-background-image {
      margin: auto;
      width: 62px;
      height: 76px;
    }
  }
}

.imprint-content {
  margin-top: 90px;
  margin-bottom: 80px;
  .title {
    font-weight: 700;
    font-size: 50px;
    line-height: 130%;
  }
  .image-mobile {
    display: none;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    justify-content: space-between;
    margin-top: 32px;
    height: 35rem;
    .pham {
      width: 100%;
      height: 60px;
      font-weight: 700;
      font-size: 20px;
      color: rgba(255, 255, 255, 0.87);
      line-height: 150%;
      .down-text {
        display: block;
      }
    }
    .am-falder {
      // width: 132px;
      height: 72px;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.87);
    }
    .content-1 {
      font-size: 16px;
      opacity: 0.87;
      .change-color {
        font-weight: 700;
        color: #4fb978;
        cursor: pointer;
      }
    }
    .content-2 {
      width: 408px;
      font-size: 16px;
      opacity: 0.87;
    }
  }
}

.imprint-image-tablet {
  display: none;
}

.imprint-image {
  margin-top: 110px;
}

// END IMPRINT
@media (max-width: 1680px) {
  .magnificent-products-subcontent-list
    .magnificent-products-subcontennt-background {
    width: 100% !important;
  }
}

//START SCREEN 1680PX
@media only screen and(max-width: 1680px) {
  // HOME
  // Nav
  .grid {
    margin: 0 140px;
  }
  #mainNav .navbar-nav .nav-item .nav-link {
    font-size: 0.75rem;
  }
  // Header
  header.masthead .masthead-heading {
    font-size: 3rem;
  }
  header.masthead .masthead-subheading {
    width: 28%;
  }
  // Who we are
  .aboutus_image .aboutus_image__img {
    width: 34.6rem;
    height: 44.2rem;
  }
  .aboutus_image .aboutus_image__svg {
    height: 27rem;
    width: 27rem;
  }
  .aboutus_image .aboutus_image__svg {
    position: absolute;
    left: -3rem;
    bottom: -3rem;
  }
  // Characters
  .character .description .title {
    font-size: 2.85rem;
  }
  .character .description .content {
    font-size: 1.14rem;
  }
  .character .description .about_us {
    font-size: 0.93rem;
  }
  .character .image {
    width: 50rem;
  }
  // AI design
  .design_ai .description .header {
    font-size: 1.3rem;
  }
  .design_ai .description .title {
    font-size: 3.21rem;
  }
  .design_ai .description .content {
    font-size: 1.14rem;
    margin-top: 2rem;
  }
  .design_ai .image {
    bottom: -9.21rem;
  }
  // Magnificent
  .magnificent .title {
    font-size: 3.6rem;
  }
  .research .description .title {
    font-size: 3.35rem;
  }
  .research .description .content {
    font-size: 1.14rem;
  }
  // Truted by
  .trusted .trustedBy_title .trusted_title {
    font-size: 3.3rem;
  }
  .trusted .trusted_left {
    font-size: 1.14rem;
  }
  .feedBack .feedBackLeft .feedBackLeft__name .feedBackLeft__name_Author {
    font-size: 2.2rem;
  }
  .feedBack
    .feedBackLeft
    .feedBackLeft__sologan
    .feedBackLeft__sologan__content {
    font-size: 1.3rem;
  }
  .feedBack
    .feedBackLeft
    .feedBackLeft__introduce
    .feedBackLeft__introduce__content {
    font-size: 1.14rem;
  }
  .contact .contact-left .contact-title-left__subtitle {
    font-size: 3.3rem;
  }
  .contact .contact-left .contact-title-left__content {
    font-size: 1.14rem;
  }
  // Fotter
  .footer .nav .item .link {
    font-size: 0.9rem;
  }
  // End Home
  // ABOUT
  .whatbai .whatbai-ceo {
    justify-content: center;
  }
  .whatbai .whatbai-title h2 {
    font-size: 3.4rem;
  }
  // Our Vision
  .ourvision
    .ourvision-questions-left
    .ourvision-questions-left-list
    .ourvision-questions-left-item__wrap {
    padding: 0px 0 0 155px;
  }
  .ourvision .ourvision-questions-right {
    padding: 111px 155px 161px 150px;
    text-align: left;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.07),
        rgba(255, 255, 255, 0.07)
      ),
      #050915;
  }
  // Our efforts
  .aboutus-activity .aboutus-activity__title {
    font-size: 3.4rem;
  }
  .aboutus-activity .aboutus-activity__quote p {
    line-height: 1.5rem;
  }
  .tab-content-right .tab-content-right-title {
    font-size: 1.5rem;
  }
  //Do you want
  .Product-top {
    margin: 105px 135px 104px 140px;
  }
  .Product-top .Product-top__title {
    font-size: 2.45rem;
  }
  .Product-bottom .Product-bottom__title {
    font-size: 2.45rem;
  }
  .Product-top .Product-top__button {
    padding: 15px 18px;
    font-size: 13px;
  }
  .Product-bottom .Product-bottom__button {
    padding: 15px 18px;
    font-size: 13px;
  }
  // .react-multi-carousel-track {
  //     .slider-image-item {
  //         width: 525px !important;
  //     }
  // }
  .carousel-container-with-scrollbar {
    padding-left: 135px !important;
  }
  // END ABOUT
  // AI DESIGN
  // Header
  // End header
  // END AI DESIGN
  //STAR IMPRINT
  //END IMPRINT
}

// END SCREEN 1680PX
//END HOME
//START ABOUT US
//END ABOUT US
//START AI-DESIGN
//END AI-DESIGN
//START PRODUCT
//END PRODUCT
//START ATHENA
//END ATHENA
//START CONTACT US
//END CONTACT US
//Screen 992
@media (min-width: 1441px) and (max-width: 1680px) {
  // header font
  header.header-aboutus .header-aboutus-heading,
  header.header-ai-design .header-ai-design-heading,
  header.header-product .header-product-heading,
  header.header-athena .heading {
    font-size: 4.25rem;
    line-height: 5.525rem;
  }
  header.header-aboutus .header-aboutus-content span,
  header.header-product .header-product-content span,
  header.header-ai-design .header-ai-design-content span {
    font-size: 1rem;
    line-height: 1.6rem;
  }
  //START ABOUT US
  .whatbai .whatbai-quote {
    margin: 0 300px;
  }
  .ourvision .ourvision-title {
    font-size: 3.125rem;
    line-height: 4.0625rem;
  }
  .ourvision .ourvision-quote {
    margin-top: 2rem;
    margin-left: 300px;
    margin-right: 300px;
  }
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-left
    .whatbai-ceo-left-infomation
    .whatbai-ceo-left-infomation__position,
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-right
    .whatbai-ceo-right-infomation
    .whatbai-ceo-right-infomation__position {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
  .whatbai .whatbai-quote .whatbai-quote-content,
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-left
    .whatbai-ceo-left-infomation
    .whatbai-ceo-left-infomation__content,
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-right
    .whatbai-ceo-right-infomation
    .whatbai-ceo-right-infomation__content,
  .ourvision .ourvision-quote p {
    font-size: 1.1rem;
    line-height: 1.6rem;
    margin-top: 2rem;
  }
  .ourvision .ourvision_tabpane {
    padding-right: 130px;
  }
  .ourvision .ourvision_tabpane .ourvision_tabpane-title {
    font-size: 1.9rem;
    line-height: 2.7625rem;
  }
  .ourvision .ourvision_tabpane .ourvision_tabpane-content {
    margin: 2rem 0;
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
  .ourvision .ourvision-questions__list .ourvision-questions__item {
    padding-left: 205px !important;
  }
  .ourvision
    .ourvision-questions__list
    .ourvision-questions__item
    .ourvision-questions__item-link {
    font-size: 1.25rem;
  }
  .ourvision .ourvision_tabpane .ourvision_tabpane-contact-link {
    font: 1rem;
    line-height: 1.5rem;
  }
  .aboutus-activity .aboutus-activity__title {
    margin: 0 300px;
  }
  .aboutus-activity .aboutus-activity__quote {
    margin: 1.1rem 200px 4rem;
  }
  .about-us__tab-list {
    padding: 0 !important;
    justify-content: center;
  }
  .tab-content-left .tab-content-left-title {
    margin: 0 226px;
  }
  .tab-content-left .tab-content-left-quote {
    margin: 0 240px;
  }
  img {
    height: auto;
  }
  .Product-top {
    margin: 80px 120px;
  }
  .Product-bottom {
    margin: 100px 250px 100px 56px;
  }
  //END ABOUT US
  // START AI DESIGN
  .information {
    margin: 0 342px;
  }
  .information .information-sologan {
    font-size: 3.4rem;
    line-height: 4.42rem;
  }
  .information .information-quote {
    font-size: 1.14rem;
    line-height: 1.6rem;
    opacity: 0.6;
  }
  // END AI DESIGN
  // START PRODUCT
  .subheader-product {
    margin: 0 200px;
  }
  .subheader-product p {
    font-size: 1.14rem;
    line-height: 1.6rem;
  }
  .section-Magnificent .grid {
    margin: 0 50px;
  }
  .section-DemoProduct .product-demo .product-demo-title,
  .magnificent-products .magnificent-products-title {
    font-size: 3.4rem;
    line-height: 4.42rem;
  }
  // .magnificent-products-subcontent-list .magnificent-products-subcontennt-background {
  //   width: 100% !important;
  // }
  .magnificent-products-subcontent-list
    .magnificent-products-subcontent
    .magnificent-products-subcontent-title {
    font-size: 1.3rem;
    line-height: 1.92rem;
  }
  //END PRODUCT
  // START ATHENA
  .athena-content {
    padding: 66px 380px;
  }
  // END ATHENA
  //START CONTACT US
  .contact1 .contact-left .contact-title-left__content {
    width: 210px;
    padding-right: 0;
  }
  //END CONTACT US
}

@media (min-width: 1280px) and (max-width: 1440px) {
  //header all screen
  header.header-aboutus .header-aboutus-heading,
  header.header-ai-design .header-ai-design-heading,
  header.header-product .header-product-heading,
  header.header-athena .heading,
  header.header-contact-us .heading {
    font-size: 4.25rem;
    line-height: 130%;
  }
  header.header-aboutus .header-aboutus-content span,
  header.header-ai-design .header-ai-design-content span,
  header.header-product .header-product-content span,
  header.header-athena .content,
  header.header-contact-us .content {
    font-size: 1rem;
    line-height: 150%;
  }
  //end header all screen
  .grid {
    margin: 0 85px;
  }
  img {
    height: auto;
  }
  header.masthead {
    top: 25%;
  }
  //START HOME
  // aboutus
  .aboutus .aboutus__title {
    font-size: 3.125rem;
  }
  .aboutus .aboutus__heading {
    font-size: 1.5rem;
    line-height: 130%s;
  }
  .aboutus .aboutus__content {
    font-size: 1rem;
    width: normal;
  }
  .aboutus .aboutus__btn-contact .aboutus__btn-contact-content {
    font-size: 0.875;
    line-height: 1rem;
  }
  .character .description .title {
    font-size: 3.125rem;
  }
  .character .description .content {
    font-size: 1rem;
  }
  .character .description .about_us {
    font-size: 0.875rem;
    line-height: 1rem;
  }
  //design_ai
  .design_ai .description .header {
    font-size: 1.5rem;
  }
  .design_ai .description .content {
    font-size: 1rem;
    margin-top: 2rem;
    padding: 0 5.5rem;
  }
  .design_ai .parent-image {
    margin-top: 0;
  }
  //magnificent
  .magnificent .title {
    font-size: 3.125rem;
    line-height: 150%;
  }
  .magnificent .magnificent__content .subTitle {
    font-size: 1rem;
  }
  .magnificent .magnificent__content .viewAll {
    font-size: 0.875rem;
  }
  .image-container-text .image-container-text-title {
    font-size: 2.125rem;
    line-height: 130%;
  }
  //research
  .research .description .title {
    font-size: 3.125rem;
  }
  .research .description .content {
    font-size: 1rem;
    width: 500px;
  }
  .page-section-research {
    background-position-x: 100%;
  }
  //trusted
  .trusted .trustedBy_title .trusted_title {
    font-size: 3.125rem;
    line-height: 130%;
  }
  .trusted .trusted_left {
    font-size: 1rem;
    line-height: 150%;
  }
  .trusted .trusted_right .trusted_button__btn {
    font-size: 0.875rem;
    line-height: 150%;
  }
  .feedBack
    .feedBackLeft
    .feedBackLeft__introduce
    .feedBackLeft__introduce__content {
    font-size: 1rem;
  }
  //contact
  .contact .contact-left .contact-left-title {
    font-size: 1.5rem;
  }
  .contact .contact-left .contact-title-left__subtitle {
    font-size: 3.125rem;
  }
  .contact .contact-left .contact-title-left__content p {
    font-size: 1rem;
  }
  .contact .contact_btn__submit {
    padding: 0;
  }
  //END HOME
  //START ABOUT-US
  // whatbai
  .whatbai .whatbai-title h2 {
    font-size: 3.125rem;
    line-height: 130%;
  }
  .whatbai .whatbai-quote {
    margin: 0 12rem;
  }
  .whatbai .whatbai-quote .whatbai-quote-content {
    font-size: 1rem;
    line-height: 1.5rem;
    opacity: 0.6;
  }
  .whatbai .whatbai-ceo .whatbai-ceo-left {
    margin-right: 50px;
    // width: 28.125rem;
  }
  .whatbai .whatbai-ceo {
    margin-top: 6rem;
  }
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-left
    .whatbai-ceo-left-infomation
    .whatbai-ceo-left-infomation__name,
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-right
    .whatbai-ceo-right-infomation
    .whatbai-ceo-right-infomation__name {
    font-size: 2.125rem;
    line-height: 130%;
  }
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-left
    .whatbai-ceo-left-infomation
    .whatbai-ceo-left-infomation__position,
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-right
    .whatbai-ceo-right-infomation
    .whatbai-ceo-right-infomation__position {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-left
    .whatbai-ceo-left-infomation
    .whatbai-ceo-left-infomation__content,
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-right
    .whatbai-ceo-right-infomation
    .whatbai-ceo-right-infomation__content {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  //ourvision
  .ourvision {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .ourvision .ourvision-title {
    font-size: 3.125rem;
    line-height: 130%;
  }
  .ourvision .ourvision-quote p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .ourvision .ourvision-questions {
    margin-top: 5rem;
  }
  .ourvision .ourvision-quote {
    margin-top: 2rem;
    margin-left: 25rem;
    margin-right: 25rem;
  }
  .ourvision .ourvision_tabpane .ourvision_tabpane-title {
    font-size: 2.125rem;
    line-height: 130%;
  }
  .ourvision .ourvision-questions__list .ourvision-questions__item {
    padding-left: 160px !important;
  }
  .ourvision
    .ourvision-questions__list
    .ourvision-questions__item
    .ourvision-questions__item-link {
    font-size: 1.14rem;
  }
  .ourvision .ourvision_tabpane .ourvision_tabpane-content {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  //section-Activity
  .aboutus-activity .aboutus-activity__title {
    font-size: 3.125rem;
    line-height: 130%;
    margin: 0 230px;
  }
  .aboutus-activity .aboutus-activity__quote {
    margin: 1.1rem 10rem 4rem;
  }
  .aboutus-activity .aboutus-activity__quote p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .about-us__tab-list {
    padding: 0 !important;
    justify-content: center;
  }
  .about-us__tab-list .about-us__tab-item .about-us__tab-item__link {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
  .tab-content-left .tab-content-left-title {
    margin: 0 10rem;
    font-size: 1.5rem;
    line-height: 150%;
  }
  .tab-content-left .tab-content-left-quote {
    margin: 0 11rem;
    font-size: 1rem;
    line-height: 150%;
  }
  .tab-content-left-img-item-left {
    height: 100%;
  }
  //section-Product
  .Product-top,
  .Product-bottom {
    margin: 65px;
  }
  .Product-top .Product-top__title,
  .Product-bottom .Product-bottom__title {
    font-size: 2.25rem;
    line-height: 130%;
  }
  .Product-top .Product-top__button,
  .Product-bottom .Product-bottom__button {
    font-size: 14px;
    line-height: 16px;
  }
  //c-footer footer
  .footer .copyright p {
    margin: 0;
    justify-content: center;
    display: flex;
    text-align: center;
  }
  //END ABOUT-US
  //START AI-DESIGN
  //information
  .information {
    margin: 0 265px;
  }
  .information .information-sologan {
    font-size: 3.125rem;
    line-height: 130%;
    margin: 0 100px;
  }
  .information .information-quote {
    font-size: 1rem;
    line-height: 150%;
    opacity: 0.6;
  }
  .idea .designai .designai-main .designai-index {
    font-size: 1.25rem;
    line-height: 150%;
  }
  .idea .designai .designai-main .designai-title {
    font-size: 2rem;
    line-height: 130%;
    margin: 16px 0 !important;
  }
  .idea .designai .designai-main .designai-content {
    font-size: 1rem;
    margin-bottom: 32px;
  }
  .idea .designai .designai-main .designai-button {
    font-size: 1rem;
    line-height: 1rem;
  }
  .idea .idea-top {
    height: 486.28px;
  }
  .idea .idea-bottom img {
    width: 650px;
  }
  //END AI-DESIGN
  //START PRODUCT
  .subheader-product {
    margin: 0px 143px;
  }
  .subheader-product p {
    font-size: 1rem;
    opacity: 0.6;
  }
  //magnificent-products
  .magnificent-products .magnificent-products-title {
    font-size: 3.125rem;
    margin: 0 280px;
    line-height: 130%;
  }
  .magnificent-products-subcontennt-background-left {
    height: 830px !important;
  }
  .magnificent-products-subcontennt-background-right {
    height: 400px !important;
  }
  .magnificent-products-subcontent-list
    .magnificent-products-subcontent
    .magnificent-products-subcontent-title {
    font-size: 1.25rem;
  }
  //product-demo
  .section-DemoProduct .product-demo .product-demo-title {
    font-size: 3.125rem;
    line-height: 130%;
  }
  //modal
  .popup-modal {
    margin-top: 160px;
  }
  .popup--Video {
    width: 100% !important;
    margin: 0 auto !important;
  }
  .popup-right .title {
    font-size: 24px;
  }
  .popup-right .content {
    font-size: 16px;
    overflow: scroll;
    height: 200px;
  }
  .popup-modal .popup-video {
    height: 382px !important;
  }
  //END PRODUCT
  //START ATHENA
  .athena-content {
    padding: 66px 220px;
  }
  .athena-content .summary {
    font-size: 1rem;
    line-height: 150%;
    opacity: 0.6;
  }
  .athena-ourfeatured .title {
    font-size: 3.125rem;
  }
  .athena_wanna .description .content {
    font-size: 3.125rem;
  }
  //END ATHENA
  //CONTACT US
  .contact1 .contact-left .contact-left-title {
    font-size: 1.35rem;
  }
  .contact1 .contact-left .contact-title-left__subtitle_content {
    font-size: 3.125rem;
  }
  .contact1 .contact-left .contact-title-left__content {
    font-size: 1.14rem;
  }
  .custom-checkbox__content {
    font-size: 0.875rem;
  }
  //END CONTACT US
  //IMPRINT
  .imprint-image {
    img {
      width: 100%;
    }
  }
  //END IMORINT
}

@media only screen and(width: 1280px) {
  //header all screen
  // header.header-aboutus .header-aboutus-heading,
  // header.header-ai-design .header-ai-design-heading,
  // header.header-product .header-product-heading,
  // header.header-athena .heading,
  // header.header-contact-us .heading {
  //   font-size: 4rem;
  //   line-height: 130%;
  // }
  header.header-aboutus .header-aboutus-content span,
  header.header-ai-design .header-ai-design-content span,
  header.header-product .header-product-content span,
  header.header-athena .content,
  header.header-contact-us .content {
    font-size: 1rem;
    line-height: 150%;
  }
  //end header all screen
  img {
    height: auto;
  }
  //START HOME
  // aboutus
  .aboutus .aboutus__title {
    font-size: 3rem;
  }
  .aboutus .aboutus__heading {
    font-size: 1.5rem;
    line-height: 130%s;
  }
  .aboutus .aboutus__content {
    font-size: 1rem;
    width: normal;
  }
  .aboutus .aboutus__btn-contact .aboutus__btn-contact-content {
    font-size: 0.875;
    line-height: 1rem;
  }
  .character .description .title {
    font-size: 3rem;
  }
  .character .description .content {
    font-size: 1rem;
  }
  .character .description .about_us {
    font-size: 0.875rem;
    line-height: 1rem;
  }
  //design_ai
  .design_ai .description .header {
    font-size: 1.25rem;
  }
  .design_ai .description .content {
    font-size: 1rem;
    margin-top: 2rem;
    padding: 0 5.5rem;
  }
  .design_ai .parent-image {
    margin-top: 0;
    height: 450px;
  }
  //magnificent
  .magnificent .title {
    font-size: 3rem;
    line-height: 150%;
  }
  .magnificent .magnificent__content .subTitle {
    font-size: 1rem;
  }
  .magnificent .magnificent__content .viewAll {
    font-size: 0.875rem;
  }
  .image-container-text .image-container-text-title {
    font-size: 2.15rem;
    line-height: 130%;
  }
  .research .description .title {
    font-size: 3rem;
  }
  .research .description .content {
    font-size: 1rem;
  }
  //trusted
  .trusted .trustedBy_title .trusted_title {
    font-size: 3rem;
    line-height: 130%;
  }
  .trusted .trusted_left {
    font-size: 1rem;
    line-height: 150%;
  }
  .trusted .trusted_right .trusted_button__btn {
    font-size: 0.875rem;
    line-height: 150%;
  }
  .feedBack
    .feedBackLeft
    .feedBackLeft__introduce
    .feedBackLeft__introduce__content {
    font-size: 1rem;
  }
  //contact
  .contact .contact-left .contact-left-title {
    font-size: 1.35rem;
  }
  .contact .contact-left .contact-title-left__subtitle {
    font-size: 3rem;
  }
  .contact .contact-left .contact-title-left__content p {
    font-size: 1rem;
  }
  //END HOME
  //START ABOUT-US
  // whatbai
  .whatbai .whatbai-title h2 {
    font-size: 3rem;
    line-height: 3.9rem;
  }
  .whatbai .whatbai-quote {
    margin: 0 12rem;
  }
  .whatbai .whatbai-quote .whatbai-quote-content {
    font-size: 1rem;
    line-height: 1.5rem;
    opacity: 0.6;
  }
  .whatbai .whatbai-ceo .whatbai-ceo-left {
    margin-right: 50px;
    // width: 28.125rem;
  }
  .whatbai .whatbai-ceo {
    margin-top: 6rem;
  }
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-left
    .whatbai-ceo-left-infomation
    .whatbai-ceo-left-infomation__name,
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-right
    .whatbai-ceo-right-infomation
    .whatbai-ceo-right-infomation__name {
    font-size: 2rem;
    line-height: 130%;
  }
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-left
    .whatbai-ceo-left-infomation
    .whatbai-ceo-left-infomation__position,
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-right
    .whatbai-ceo-right-infomation
    .whatbai-ceo-right-infomation__position {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-left
    .whatbai-ceo-left-infomation
    .whatbai-ceo-left-infomation__content,
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-right
    .whatbai-ceo-right-infomation
    .whatbai-ceo-right-infomation__content {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  //ourvision
  .ourvision {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .ourvision .ourvision-title {
    font-size: 3rem;
    line-height: 3.9rem;
  }
  .ourvision .ourvision-quote p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .ourvision .ourvision-questions {
    margin-top: 5rem;
  }
  .ourvision .ourvision-quote {
    margin-top: 2rem;
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .ourvision .ourvision_tabpane .ourvision_tabpane-title {
    font-size: 2rem;
    line-height: 2.6rem;
  }
  .ourvision .ourvision-questions__list .ourvision-questions__item {
    padding-left: 100px !important;
  }
  .ourvision
    .ourvision-questions__list
    .ourvision-questions__item
    .ourvision-questions__item-link {
    font-size: 1.14rem;
  }
  .ourvision .ourvision_tabpane .ourvision_tabpane-content {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  //section-Activity
  .aboutus-activity .aboutus-activity__title {
    font-size: 3rem;
    line-height: 3.9rem;
    margin: 0 270px;
  }
  .aboutus-activity .aboutus-activity__quote {
    margin: 1.1rem 11rem 4rem;
  }
  .aboutus-activity .aboutus-activity__quote p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .about-us__tab-list {
    padding: 0 !important;
    justify-content: center;
  }
  .about-us__tab-list .about-us__tab-item .about-us__tab-item__link {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
  .tab-content-left .tab-content-left-title {
    margin: 0 10rem;
    font-size: 1.5rem;
    line-height: 130%;
  }
  .tab-content-left .tab-content-left-quote {
    margin: 0 11rem;
    font-size: 1rem;
    line-height: 150%;
  }
  .tab-content-left-img-item-left {
    height: 100%;
  }
  //section-Product
  .Product-top,
  .Product-bottom {
    margin: 90px 70px;
  }
  .Product-top .Product-top__title,
  .Product-bottom .Product-bottom__title {
    font-size: 2rem;
    line-height: 2.6rem;
  }
  .Product-top .Product-top__button,
  .Product-bottom .Product-bottom__button {
    font-size: 10px;
    line-height: 15px;
  }
  //END ABOUT-US
  //START AI-DESIGN
  //information
  .information {
    margin: 0 200px;
  }
  .information .information-sologan {
    font-size: 3rem;
    line-height: 130%;
  }
  .information .information-quote {
    font-size: 1rem;
    line-height: 150%;
  }
  //END AI-DESIGN
  //START PRODUCT
  //magnificent-products
  .magnificent-products .magnificent-products-title {
    font-size: 3rem;
    line-height: 130%;
  }
  //product-demo
  .section-DemoProduct .product-demo .product-demo-title {
    font-size: 3rem;
    line-height: 130%;
  }
  //END PRODUCT
  //START ATHENA
  .athena-ourfeatured .title {
    font-size: 3rem;
  }
  .athena_wanna .description .content {
    font-size: 3rem;
  }
  //END ATHENA
  //CONTACT US
  .contact1 .contact-left .contact-left-title {
    font-size: 1.35rem;
  }
  .contact1 .contact-left .contact-title-left__subtitle_content {
    font-size: 3rem;
  }
  .contact1 .contact-left .contact-title-left__content {
    font-size: 1.14rem;
  }
  .custom-checkbox__content {
    font-size: 0.875rem;
  }
  //END CONTACT US
}

@media (min-width: 1025px) {
  #mainNav {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border: none;
    background-color: transparent;
    transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
  }
  #mainNav .navbar-brand {
    font-size: 1.5em;
    transition: font-size 0.3s ease-in-out;
  }
  #mainNav .navbar-brand img {
    height: 1.5625rem;
    transition: height 0.3s ease-in-out;
  }
  #mainNav.navbar-shrink {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #050915;
  }
  #mainNav.navbar-shrink .navbar-brand {
    font-size: 1.25em;
  }
  #mainNav.navbar-shrink .navbar-brand svg,
  #mainNav.navbar-shrink .navbar-brand img {
    height: 1.5rem;
  }
  #mainNav .navbar-nav .nav-item {
    margin-right: 1rem;
  }
  #mainNav .navbar-nav .nav-item:last-child {
    margin-right: 0;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

// @media only screen and(max-width: 1024px) {
//  .Product-top,
//  .Product-bottom {
//    margin: 40px 0;
//  }
// }
@media (max-width: 1024px) {
  //NAVIGATION
  header.header-policy {
    .header-content {
      .title-cookies-policy {
        font-size: 50px;
      }
      .header-policy-navigation {
        .home-link {
        }
      }
      .header-policy-content {
      }
    }
  }
  .policy {
    flex-direction: column;
    .policy-menu {
      font-size: 16px;
      height: 300px;
      margin-bottom: 21%;
      .policy-menu-title {
      }
      .policy-menu-tab-menu {
        font-size: 16px;
        line-height: 150%;
        .policy_underlined {
        }
      }
      .policy-menu-tab-menu:focus {
      }
    }
    .policy-content {
      .policy-conetent-item {
        .policy-content-title {
          font-size: 24px;
          line-height: 130%;
        }
        .policy-content-content {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
  .modal__wrapper {
    width: 690px;
  }
  .language {
    margin-top: 28px;
    .language__toggle {
      background: rgba(255, 255, 255, 0.87);
      border-radius: 23px;
    }
  }

  .grid {
    margin: 0 15px;
  }
  //END NAVIGATION
  .formSubmitting {
    float: none !important;
    width: 67%;
  }
  .nav__mobile {
    display: flex;
    padding: 0 30px;
    align-items: center;
  }
  .nav__mobile-close {
    top: calc(10% - 50px);
    right: 3rem;
  }
  .nav__bars-btn {
    display: block;
  }
  .navbar__desktop {
    display: none;
  }
  .navbar__mobile {
    display: block;
    z-index: 999;
  }
  .navbar__mobile-logo img {
    height: auto;
  }
  .go-to-top {
    display: none;
  }
  .navbar__mobile--open {
    display: flex;
    visibility: visible;
    transform: translateX(0);
    opacity: 1;
  }
  .nav__overlay--open {
    display: block;
  }
  .aboutus_image {
    .aboutus_image__img {
      transform: none !important;
    }
  }
  .aboutus_image .aboutus_image__svg {
    left: -6rem;
    bottom: -2rem;
  }
  .carousel-container-with-scrollbar {
    padding-left: 0 !important;
  }
  .react-multi-carousel-track {
    // transform: translate3d(-349px, 0px, 0px)!important;
    .slider-image-item {
      //  width: 512px !important;
      margin-top: 40px;
    }
  }
  //HOME
  .aboutus .aboutus__content {
    width: 96%;
  }
  .character .image {
    width: 98%;
    height: 434px;
  }
  .character .description {
    margin-left: 0;
  }
  // .vectorImage {
  // }
  .design_ai .description .content {
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 0%;
  }
  .feedBack {
    padding-top: 4.5rem;
    .feedBackLeft {
      .feedBackLeft__introduce {
        width: 70%;
      }
    }
  }
  .design_ai {
    .parent-image {
      // height: auto;
      width: 100%;
      margin-top: -12%;
    }
  }
  .magnificent {
    padding-top: 168px;
  }
  .magnificent .magnificent__content {
    padding: 0;
    margin: 1.5rem 0;
  }
  .magnificent .title {
    width: 60%;
  }
  .trusted {
    padding-top: 8.571428571428571rem;
  }
  .page-section {
    margin: 0;
    margin-top: 94px;
  }
  .aboutus {
    min-height: 700px;
  }
  //END HOME
  //START ABOUT-US
  .Product-top,
  .Product-bottom {
    margin: 40px 0;
  }
  .ourvision .ourvision-quote p {
    padding: 0 11.3125rem;
  }
  //END ABOUT-US
  .image-container {
    background-position: center center;
  }
  //ATHENA
  .popup-modal {
    .button-prev {
      left: -7%;
    }
    .button-next {
      right: -7%;
    }
  }
  .popup--Video {
    width: 98% !important;
    margin-left: 0;
    margin-top: -3%;
  }
  .popup-modal .popup-video {
    height: 431px !important;
  }
  .popup-right .content {
    overflow: scroll;
    text-overflow: ellipsis;
    display: -webkit-box;
    height: 72px;
  }
  .popup-right .content::-webkit-scrollbar {
    display: none;
  }
  .popup-right .title {
    margin-top: 32px;
    margin-bottom: 24px;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .popup-right .content {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  //Sub-header
  // End  sub header
  //Our featured
  // End our freter
  //wanna
  //end wanna
  .athena_wanna {
    padding: 7% 19%;
    text-align: center;
    .description {
      position: initial;
    }
  }
  .athena-ourfeatured .product .content {
    width: 90%;
  }
  .athena-ourfeatured .product .product-img {
    width: 100%;
    height: 339px;
    transition: transform 0.2s;
  }
  //END ATHENA
  //AI-DESIGN
  .section-idea1 .idea .idea-bottom .idea-bottom-image {
    width: 540px !important;
    height: 530px;
  }
  .section-idea2 .idea .idea-bottom .idea-bottom-image {
    width: 540px !important;
    height: 530px;
  }
  .section-idea3 .idea .idea-bottom .idea-bottom-image {
    width: 540px !important;
    height: 530px;
  }
  .section-idea4 .idea .idea-bottom .idea-bottom-image {
    width: 540px !important;
    height: 530px;
  }
  .section-idea5 .idea .idea-bottom .idea-bottom-image {
    width: 540px !important;
    height: 530px;
  }
  .idea .idea-top {
    height: 530px;
  }
  .imgage-left {
    left: 44px;
  }
  .image-right {
    left: -65px;
  }
  .idea .designai .designai-main .designai-title {
    font-size: 24px;
    margin: 16px 0 !important;
    line-height: 130%;
    letter-spacing: 0.18px;
  }
  .idea .designai .designai-main {
    width: 100%;
    height: 480px;
  }
  .idea .designai .designai-main .designai-index {
    font-size: 20px;
    line-height: 150%;
  }
  .idea .designai .designai-main .designai-content {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }
  .idea .designai .designai-main //END AI-DESIGN
    // PRODUCT
    header.header-product {
    padding-top: 9.07rem;
    padding-bottom: 4.07rem;
    .header-product-sticky {
      border: 2px solid rgba(255, 255, 255, 0.87);
    }
    .header-product-heading {
      font-size: 3.571428571rem;
      line-height: 4.642857143rem;
      margin-bottom: 1.1rem;
    }
    .header-product-content {
      font-size: 1.1rem;
      line-height: 1.7rem;
    }
  }
  .subheader-product {
    margin: 0 164px;
    p {
      font-size: 1.1rem;
      line-height: 1.7rem;
    }
  }
  .magnificent-products {
    padding-bottom: 124px;
    .magnificent-products-title {
      margin: 0 164px;
      font-size: 2.957142857rem;
      line-height: 3.714285714rem;
    }
  }
  .magnificent-products-subcontent-list {
    .magnificent-products-subcontennt-background {
      width: 100% !important;
      height: 246px !important;
    }
    .magnificent-products-subcontennt-background-left {
      height: 522px !important;
      background-position: center center;
    }
  }
  .magnificent-products-subcontent-list .magnificent-products-subcontent {
    left: 30px;
  }
  .section-DemoProduct .product-demo {
    padding: 113px 0;
    text-align: center;
    .product-demo__button {
      font-size: 1rem;
    }
  }
  .contact {
    padding: 5.7rem 0;
  }
  // END PRODUCT
  // START CONTACT-US
  // END CONTACT-US
  //ATHENA
  //END AHTEMA
  //IMPRINT
  header.header-imprint .header-imprint-content {
    height: 134px;
  }
  .ourvision_tabpane-control {
    display: flex !important;
    width: 100%;
  }
  .slider-image-item {
    padding: 60px 0 20px 10px !important;
  }
  .react-multiple-carousel__arrow--right {
    right: 0 !important;
    top: 10px;
    left: 120px;
    width: 43px;
  }
  .react-multiple-carousel__arrow--left {
    top: 10px;
    left: 0;
  }
  //END IMPRINT
}

@media (min-width: 768px) and (max-width: 1024px) {
  //header-tablet
  .popup--Video {
    width: 98%;
    margin-left: 0;
    margin-top: -3%;
    .show-grid {
      padding: 1rem !important;
    }
  }
  header.header-aboutus {
    background-image: url(../assets/images/header-ab-us-tablet.png);
    margin-top: 72px;
    background-size: 100% 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  header.header-ai-design {
    background-image: url(../assets/images/header-ai-tablet.png);
    margin-top: 72px;
    background-size: 100% 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  header.header-product {
    background-image: url(../assets/images/header-product-tablet.png);
    margin-top: 72px;
    background-size: 100% 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  header.header-athena {
    background-image: url(../assets/images/header_athena.png);
    margin-top: 72px;
    background-size: 100% 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  header.header-contact-us {
    background-image: url(../assets/images/header-ct-us-tablet.png);
    margin-top: 72px;
    background-size: 100% 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  //end header tablet
  .contact1 {
    .form__group-contact {
      .ipad-text {
        height: 140px;
      }
    }
  }
  .offset-md-1 {
    // margin-left: 0;
    // padding-top: 6.375rem;
  }
  //HOME
  .feedBack .feedBackRight__sticky {
    display: none;
  }
  .formSubmitting {
    width: 25%;
  }
  .research .description .content {
    padding-left: 12%;
    padding-right: 12%;
  }
  //END HOME
  // .navbar__mobile {
  //   display: block;
  //   z-index: 999;
  // }
  //Nav
  header.masthead .masthead-sticky {
    width: 11.625rem;
    border: 1px solid rgba(255, 255, 255, 0.87);
  }
  header.masthead .masthead-heading {
    font-size: 3.5rem;
  }
  header.masthead {
    top: 10%;
  }
  header.masthead .masthead-subheading {
    width: 35%;
  }
  //End Navc
  .research {
    text-align: center;
    .description {
      .content {
        width: 100%;
        margin-top: 2%;
      }
    }
  }
  .page-section-research {
    background-image: url(../assets/images/ipad/Home/header-research-ipad.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .trusted .trusted_right {
    display: none;
  }
  // btn-success
  .c-dark-theme .btn-success {
    border-top-width: 0rem;
    border-bottom-width: 0rem;
    padding: 16px 0;
    margin-top: 32px;
    font-size: 0.8rem;
  }
  //START ABOUT US
  //start whatbai
  .whatbai {
    padding-top: 4rem;
    padding-bottom: 4rem;
    .whatbai-title h2 {
      font-size: 2.5rem;
    }
    .whatbai-quote {
      margin: 0;
      .whatbai-quote-content {
        font-size: 1rem;
        padding: 0 10.25rem;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .whatbai-ceo {
      margin: 4rem 0 0 0;
      .whatbai-ceo-left {
        margin: 0;
        padding-left: 39px;
        padding-right: 20px;
        width: 50%;
      }
      .whatbai-ceo-right {
        width: 50%;
        padding-left: 20px;
        padding-right: 39px;
      }
    }
  }
  .whatbai-ceo-left-image img,
  .whatbai-ceo-right-image img {
    width: 100%;
    height: auto;
  }
  .whatbai .whatbai-ceo .whatbai-ceo-left .whatbai-ceo-left-infomation,
  .whatbai .whatbai-ceo .whatbai-ceo-right .whatbai-ceo-right-infomation {
    margin-top: 2rem;
  }
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-left
    .whatbai-ceo-left-infomation
    .whatbai-ceo-left-infomation__position,
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-right
    .whatbai-ceo-right-infomation
    .whatbai-ceo-right-infomation__position {
    margin: 1rem 0;
    font-size: 1rem;
    line-height: 1.5rem;
    color: rgba(255, 255, 255, 0.6);
  }
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-left
    .whatbai-ceo-left-infomation
    .whatbai-ceo-left-infomation__content,
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-right
    .whatbai-ceo-right-infomation
    .whatbai-ceo-right-infomation__content {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  //end whatbai
  // start ourvision
  .ourvision {
    margin: 0;
    padding-top: 6.875rem;
    padding-bottom: 6.125rem;
    .ourvision-title {
      font-size: 2.5rem;
      line-height: 3.25rem;
    }
    .ourvision-quote {
      margin: 20px;
      p {
        font-size: 1rem;
        line-height: 1.5rem;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .ourvision-questions {
      padding-right: 2rem;
      margin-top: 10rem;
    }
    .ourvision-questions__list {
      padding: 0;
      .ourvision-questions__item {
        padding-left: 2rem !important;
        .ourvision-questions__item-link {
          font-size: 1.25rem;
        }
      }
    }
    .ourvision_tabpane {
      padding: 0 1.5rem;
      .ourvision_tabpane-title {
        font-size: 2.125rem;
        margin-top: 0;
        line-height: 2.7625rem;
      }
    }
    .ourvision_tabpane-content {
      margin: 2rem 0;
      font-size: 1rem;
      line-height: 1.6rem;
      overflow: scroll;
      text-overflow: ellipsis;
      // display: -webkit-box;
      height: 200px;
    }
    .ourvision_tabpane-content::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .ourvision_tabpane-content {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
  .ourvision_part-right {
    padding-top: 6.375rem;
  }
  .ourvision .ourvision_tabpane .ourvision_tabpane-img-B {
    top: -180px;
    left: 64px;
    width: 130px;
    height: 150px;
  }
  .ourvision .ourvision_tabpane .ourvision_tabpane-img-C,
  .ourvision .ourvision_tabpane .ourvision_tabpane-img-S,
  .ourvision .ourvision_tabpane .ourvision_tabpane-img-D,
  .ourvision .ourvision_tabpane .ourvision_tabpane-img-N {
    top: -183px;
    left: 50px;
  }
  .ourvision .ourvision_tabpane .ourvision_tabpane-img-elip {
    top: -230px;
    left: -10px;
    width: 161.44px;
    height: auto;
    opacity: 1;
  }
  .tab-content > .active {
    margin-top: 0;
  }
  .control-button {
    display: block !important;
    margin-bottom: 2.5rem;
  }
  // end ourvision
  // start aboutus-activity
  img {
    height: 100%;
  }
  .aboutus-activity {
    padding: 4rem 0;
  }
  .aboutus-activity .aboutus-activity__title {
    margin: 0;
    font-size: 2.5rem;
    line-height: 3.25rem;
    padding: 0 6.75rem;
    margin-bottom: 2rem;
  }
  .aboutus-activity .aboutus-activity__quote {
    margin: 0;
  }
  .aboutus-activity .aboutus-activity__quote p {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0 9.3125rem;
    margin-bottom: 12px;
  }
  .about-us__tab-list {
    justify-content: center;
  }
  .about-us__tab-list .about-us__tab-item .about-us__tab-item__link {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    padding: 8px 20px 21px 20px !important;
  }
  .about-us__tab-list {
    padding: 0 !important;
  }
  .tab-content-left .tab-content-left-title,
  .tab-content-middle .tab-content-middle-title,
  .tab-content-right .tab-content-right-title {
    font-size: 1.5rem;
    padding: 2.5rem 3.1875rem 0 3.1875rem;
    line-height: 1.95rem;
    margin: 0;
  }
  .tab-content-left .tab-content-left-quote,
  .tab-content-middle .tab-content-middle-quote,
  .tab-content-right .tab-content-right-quote {
    margin: 0;
  }
  .tab-content-left .tab-content-left-quote p,
  .tab-content-middle .tab-content-middle-quote p,
  .tab-content-right .tab-content-right-quote p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
    padding: 1.5rem 3.5625rem 0 3.5625rem;
  }
  .tab-content-left .tab-content-left-img-list,
  .tab-content-middle .tab-content-middle-img-list,
  .tab-content-right .tab-content-right-img-list {
    margin-left: 0;
    margin-right: 0;
    margin-top: 2.5rem;
    padding: 0 1rem;
  }
  .item-img-right {
    padding: 0 1rem 0 5px;
  }
  .icon-img-down-left {
    padding-top: 5px !important;
    padding-right: 10px;
  }
  .icon-img-down-right {
    padding-top: 5px !important;
    padding-left: 10px;
  }
  // end aboutus-activity
  //start section-Product
  // .Product-top,
  // .Product-bottom {
  //   margin: 0;
  // }
  .Product-top .Product-top__title,
  .Product-bottom .Product-bottom__title {
    font-size: 2.125rem;
    padding: 1rem 1rem 1.5rem 1rem;
    margin: 0;
  }
  .Product-top .Product-top__button,
  .Product-bottom .Product-bottom__button {
    font-size: 0.875rem;
    line-height: 1rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
  }
  .Product-top-img {
    background-image: url(../assets/images/aboutus/ProductTop-768-1024.png);
    background-size: 100% 100%;
  }
  .Product-bottom-img {
    background-image: url(../assets/images/aboutus/ProductBottom-768-1024.png);
    background-size: 100% 100%;
  }
  .Product-bottom-img-mobile {
    display: none;
  }
  //end section-Product
  //END ABOUT US
  //ATHENA
  .athena-content {
    text-align: center;
    padding: 5% 25%;
  }
  .athena_wanna .description .content {
    width: 100%;
  }
  .contact .contact-left {
    width: 100%;
  }
  //Sub-header
  // End  sub header
  //Our featured
  // End our freter
  //wanna
  .section-Contact {
    background-image: url(../assets/images/athena/bg_wanna-tablet.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  //end wanna
  //END ATHENA
  // AI DESIGN
  header.header-ai-design {
    .header-ai-design-sticky {
      border: 2px solid rgba(255, 255, 255, 0.87);
      width: 76px;
    }
    .header-ai-design-heading {
      font-size: 3.15rem;
      line-height: 130%;
    }
    .header-ai-design-content {
      font-size: 1.1rem;
      line-height: 1.7rem;
    }
  }
  .information {
    margin: 0 134px;
    padding: 64px 0;
    .information-sologan {
      font-size: 2.35rem;
      line-height: 130%;
    }
    .information-quote {
      margin-top: 1.28rem;
    }
  }
  // END AI DESIGN
  // PRODUCT
  .section-DemoProduct {
    background-image: url(../assets/images/product/demo_image-tablet.png);
  }
  // END PRODUCT
  // CONTACT-US
  .contact1 {
    padding: 5rem 0;
  }
  .contact1 .contact-left .contact-left-title {
    font-size: 1.5rem;
    line-height: 1.95rem;
  }
  .contact1 .contact-left .contact-title-left__subtitle_content {
    font-size: 2.5rem;
    line-height: 3.25rem;
    padding-right: 4rem;
  }
  .contact1 .contact-left .content {
    width: 338px;
    padding-right: 5rem;
  }
  .contact1 .form-control.is-valid.form__field-contact textarea {
    height: 140px;
  }
  .form__group-contact {
    margin-top: 0;
  }
  .contact1 .contact-left .contact-title-left__content {
    padding-top: 1rem;
    width: 210px;
    padding-right: 0;
  }
  .custom-control-label .label-checkbox {
    padding-left: 5px;
  }
  .custom-checkbox__content {
    font-size: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  // .contact1 .contact_btn__submit {
  //   font-size: 0.9rem;
  // }
  .formSubmitting-contact {
    width: auto;
  }
  // END CONTACT-US
  //START IMPRINT
  header.header-imprint {
    background-image: url(../assets/images/imprint/header-imprint-tablet.png);
    padding-top: 21.179rem;
    height: 464px;
    margin-top: 72px;
  }
  .header-imprint-circle {
    height: 151px !important;
  }
  .imprint-content {
    margin: 24px 0 0;
  }
  .imprint-content .title {
    font-size: 2.5rem;
  }
  .imprint-content .content {
    margin-top: 1rem;
  }
  .imprint-content .content .pham {
    font-size: 1rem;
    font-weight: normal;
  }
  .imprint-content .content .am-falder {
    width: 130px;
  }
  .imprint-image {
    display: none;
  }
  .imprint-image-tablet {
    display: block;
    padding-top: 6rem;
  }
  .imprint-image-tablet img {
    width: 100%;
  }
  .imprint-content .content .content-1 {
    width: 700px;
    margin-top: 1rem;
  }
  .imprint-content .content .content-2 {
    padding-right: 16rem;
    width: 700px;
    margin-top: 1rem;
  }
  //END IMPRINT
}

@media (min-width: 769px) and (max-width: 812px) {
  .nav__mobile-close {
    top: calc(10% - 0px);
  }
  //HOME
  .aboutus .aboutus__heading {
    width: 75%;
  }
  .aboutus .aboutus__content {
    width: 75%;
  }
  .aboutus_image .aboutus_image__img {
    width: 24rem;
    height: 32.5rem;
  }
  .aboutus_image .aboutus_image__svg {
    height: 24rem;
    width: 24rem;
    left: -3rem;
    bottom: 11rem;
  }
  //END HOME
  .idea .designai .designai-main {
    width: 100%;
    height: 470px;
  }
  .section-idea1 .idea .idea-bottom .idea-bottom-image {
    width: 430px !important;
  }
  .section-idea2 .idea .idea-bottom .idea-bottom-image {
    width: 430px !important;
  }
  .section-idea3 .idea .idea-bottom .idea-bottom-image {
    width: 430px !important;
  }
  .section-idea4 .idea .idea-bottom .idea-bottom-image {
    width: 430px !important;
  }
  .section-idea5 .idea .idea-bottom .idea-bottom-image {
    width: 430px !important;
  }
  .navbar__mobile-link {
    margin-top: 5px;
  }
  .navbar__mobile-logo {
    img {
      width: 100%;
    }
    bottom: 6 !important;
    right: 0;
  }
  .footer {
    text-align: center;
    height: 100%;
    .logo {
      order: 1;
      padding-top: 1rem;
    }
    .menu {
      .footer-menu-col {
        padding: 0 !important;
      }
      .nav {
        flex-direction: row;
        justify-content: center;
      }
      order: 2;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
    .copyright {
      text-align: center;
      order: 3;
    }
  }
}

@media (max-width: 768px) {
  .ourvision
    .ourvision-questions__list
    .ourvision-questions__item
    .ourvision-questions__item-link {
    font-size: 1.1rem;
  }
  .ourvision
    .ourvision-questions__list
    .ourvision-questions__item
    .ourvision-questions__item-link
    i {
    margin: 0;
  }
  .grid {
    margin: 0 15px;
  }
  .nav__mobile {
    display: flex;
    padding: 0 30px;
  }
  .nav__bars-btn {
    display: block;
  }
  .navbar__desktop {
    display: none;
  }
  .footer {
    text-align: center;
    height: 100%;
    .logo {
      order: 1;
      padding-top: 1rem;
      margin-left: 0;
    }
    .menu {
      .footer-menu-col {
        padding: 0 !important;
      }
      .nav {
        flex-direction: row;
        justify-content: center;
      }
      order: 2;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
    .copyright {
      text-align: center;
      order: 3;
    }
  }
  // .navbar__mobile {
  //   display: block;
  //   z-index: 999;
  // }
  //Nav
  header.masthead .masthead-sticky {
    width: 11.625rem;
    border: 1px solid rgba(255, 255, 255, 0.87);
  }
  header.masthead .masthead-heading {
    font-size: 2.5rem;
  }
  header.masthead {
    top: 13%;
  }
  header.masthead .masthead-subheading {
    width: 47%;
  }
  //End Navc
  .go-to-top {
    display: none;
  }
  .navbar__mobile--open {
    transform: translateX(0);
  }
  .nav__overlay--open {
    display: block;
  }
  .video_header {
    .overlay {
      margin-top: 72px;
    }
  }
  .aboutus_image {
    .aboutus_image__img {
      transform: translateY(0) !important;
    }
  }
  .research {
    text-align: center;
    .description {
      .content {
        width: 100%;
        margin-top: 2%;
      }
    }
  }
  .page-section-research {
    background-image: url(../assets/images/ipad/Home/header-research-ipad.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .trusted .trusted_right {
    display: none;
  }
  //HOME
  //Who we are
  .aboutus .aboutus__content {
    width: 75%;
  }
  .aboutus_image .aboutus_image__img {
    border-radius: 80px;
  }
  .aboutus_image .aboutus_image__img {
    width: 24rem;
    height: 32.5rem;
  }
  .aboutus .aboutus__heading {
    width: 75%;
  }
  .aboutus_image .aboutus_image__svg {
    left: -4rem;
    bottom: 11rem;
  }
  .aboutus_image .aboutus_image__svg {
    height: 24rem;
    width: 24rem;
  }
  .aboutus {
    min-height: 0px;
  }
  //End we are
  //Charater
  .character .image {
    width: 98%;
    height: 424px;
  }
  .character .description {
    margin-left: 0rem;
    margin-top: 18.375rem;
  }
  .character .description .content {
    width: 96%;
  }
  //End character
  //Ai design
  .design_ai {
    .parent-image {
      height: 286px;
      .image {
        bottom: -10.4rem;
      }
    }
  }
  .design_ai .description .content {
    padding-left: 6%;
    padding-right: 6%;
  }
  //END AI design
  //Mafnificent
  .magnificent .title {
    width: 84%;
  }
  // End mànificent
  //Research
  // End research
  //END HOME
  //CONTACT US
  .Product-top,
  .Product-bottom {
    margin: 0;
  }
  .contact1 .formSubmitting {
    width: auto;
  }
  .formSubmitting {
    width: 30%;
  }
  .contact .contact_btn__submit {
    font-size: 0.8rem;
  }
  //END CONTACT US
  //ATHENA
  .popup-modal .popup-video {
    height: 266px !important;
  }
  //END ATHENA
  .ourvision
    .ourvision-questions__list
    .ourvision-questions__item
    .ourvision-questions__item-link {
    font-size: 1.1rem;
  }
  .ourvision .ourvision-quote p {
    padding: 0 7.3125rem;
  }
  .ourvision
    .ourvision-questions__list
    .ourvision-questions__item
    .ourvision-questions__item-link
    i {
    margin: 0;
  }
  .imgage-left {
    left: 14px;
  }
  .image-right {
    left: -72px;
  }
  .ourvision .ourvision_tabpane .ourvision_tabpane-img-elip {
    top: -230px;
    left: -10px;
    width: 161.44px;
    height: auto;
    opacity: 1;
  }
  .ourvision .ourvision_tabpane .ourvision_tabpane-img-B {
    top: -180px;
    left: 64px;
    width: 130px;
    height: 150px;
  }
  .ourvision .ourvision_tabpane .ourvision_tabpane-img-C,
  .ourvision .ourvision_tabpane .ourvision_tabpane-img-S,
  .ourvision .ourvision_tabpane .ourvision_tabpane-img-D,
  .ourvision .ourvision_tabpane .ourvision_tabpane-img-N {
    top: -183px;
    left: 50px;
  }
  .ourvision .ourvision_tabpane .ourvision_tabpane-contact-link {
    font-size: 0.9rem;
  }
  .idea {
    padding: 0;
  }
  .section-idea1 {
    padding-top: 80px;
    margin-bottom: 80px;
  }
  .section-idea2 {
    margin-bottom: 80px;
  }
  .section-idea3 {
    margin-bottom: 80px;
  }
  .section-idea4 {
    margin-bottom: 80px;
  }
  .section-idea5 {
    padding-bottom: 80px;
  }
  // .navbar__mobile {
  //   display: block;
  //   z-index: 999;
  // }
  //Nav
  header.masthead .masthead-sticky {
    width: 11.625rem;
    border: 1px solid rgba(255, 255, 255, 0.87);
  }
  header.masthead .masthead-heading {
    font-size: 2.5rem;
  }
  header.masthead .masthead-subheading {
    width: 47%;
  }
  //End Navc
  .research {
    text-align: center;
    .description {
      .content {
        width: 100%;
        margin-top: 2%;
      }
    }
  }
  .page-section-research {
    background-image: url(../assets/images/ipad/Home/header-research-ipad.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .trusted .trusted_right {
    display: none;
  }
  //HOME
  //Who we are
  .aboutus .aboutus__content {
    width: 75%;
  }
  .aboutus_image .aboutus_image__img {
    border-radius: 80px;
  }
  .aboutus_image .aboutus_image__img {
    width: 100%;
    height: 32.5rem;
  }
  .aboutus .aboutus__heading {
    width: 75%;
  }
  .aboutus_image .aboutus_image__svg {
    left: -4rem;
    bottom: 11rem;
  }
  .aboutus_image .aboutus_image__svg {
    height: 24rem;
    width: 24rem;
  }
  .aboutus {
    min-height: 0px;
  }
  //End we are
  //Charater
  .character .image {
    width: 395px;
    height: 312px;
  }
  .character .description {
    margin-left: 0rem;
    margin-top: 18.375rem;
  }
  .character .description .content {
    width: 96%;
  }
  //End character
  //Ai design
  .design_ai {
    .parent-image {
      margin-top: -4%;
      .image {
        bottom: -10.4rem;
        height: auto;
      }
    }
  }
  .design_ai .description .content {
    padding-left: 6%;
    padding-right: 6%;
  }
  //END AI design
  //Mafnificent
  .magnificent .title {
    width: 84%;
  }
  // End mànificent
  //Research
  // End research
  //END HOME
  //ATHENA
  header.header-athena .heading {
    font-size: 50px;
  }
  //Sub-header
  .athena-content {
    padding: 66px 164px;
    .summary {
      letter-spacing: 0.15px;
    }
  }
  // End  sub header
  //Our featured
  // End our freter
  //wanna
  //end wanna
  .athena_wanna {
    padding: 7% 15%;
    text-align: center;
    .description {
      position: initial;
      .athenan_wanna__button {
        margin-top: 25px !important;
        font-size: 0.8rem;
      }
    }
  }
  //END ATHENA
  // AI DESIGN
  .section-idea1 .idea .idea-bottom .idea-bottom-image {
    width: 430px !important;
  }
  .section-idea2 .idea .idea-bottom .idea-bottom-image {
    width: 430px !important;
  }
  .section-idea3 .idea .idea-bottom .idea-bottom-image {
    width: 430px !important;
  }
  .section-idea4 .idea .idea-bottom .idea-bottom-image {
    width: 430px !important;
  }
  .section-idea5 .idea .idea-bottom .idea-bottom-image {
    width: 430px !important;
  }
  .idea .designai .designai-main .designai-title {
    line-height: 1.5rem;
    font-size: 1.4rem;
  }
  .idea .designai .designai-main .designai-content {
    margin-bottom: 16px;
    font-size: 0.9rem;
  }
  .designai-main {
    width: 100% !important;
    padding: 20px !important;
  }
  .mt-5,
  .my-5 {
    margin-top: 16px !important;
  }
  // END AI DESIGN
  // START ABOUT US
  .control-button {
    display: block !important;
  }
  .Product-top .Product-top__title,
  .Product-bottom .Product-bottom__title {
    padding-top: 10px;
  }
  .Product-top-img {
    background-image: url(../assets/images/aboutus/ProductTop-768-1024.png);
    background-size: 100% 100%;
    height: 219px;
  }
  .Product-bottom-img {
    background-image: url(../assets/images/aboutus/ProductBottom-768-1024.png);
    background-size: 100% 100%;
    height: 219px;
  }
  // ATHENA
  .popup-modal {
    .button-prev {
      left: -11% !important;
    }
    .button-next {
      right: -11% !important;
    }
  }
  //END ATHENA
}

// END SCREEN 992
// RESPONSIVE
@media (max-width: 480px) {
  //STATR NAV
  // #mainNav {
  //   max-width: 100%;
  //   display: contents;
  // }
  //popup-video
  header.header-policy .header-content {
    width: 305px;
  }
  header.header-policy .header-content .title-cookies-policy {
    font-size: 40px;
  }
  .popup--Video {
    width: 98%;
    margin-left: 0;
    margin-top: 10%;
    .show-grid {
      padding: 1rem !important;
    }
  }
  .popup-modal .popup-video {
    height: 192px !important;
  }
  .popup-right .title {
    font-size: 20px;
    margin: 0;
    margin-top: 20px;
  }
  .popup-right .content {
    margin: 0;
    margin-top: 16px;
    overflow: scroll;
    height: 124px !important;
  }
  .popup-modal .button-close {
    right: 0;
    top: -8%;
  }
  .popup-modal .button-next {
    display: none;
  }
  .popup-modal .button-prev {
    display: none;
  }
  .popup-modal {
    .button-mobile {
      display: flex;
      justify-content: space-around;
      font-weight: 900;
      font-size: 32px;
      line-height: 37px;
      color: rgba(255, 255, 255, 0.6);
      margin: 0 !important;
      padding: 5% 28% !important;
    }
  }
  .popup--Video {
    height: 500px !important;
  }
  //end popup video
  .image-container-text {
    left: 1rem !important;
  }
  //chance header imgage
  header.header-aboutus {
    background-image: url(../assets/images/aboutus/header_about-us.png);
    background-size: 100% 100%;
    margin-top: 4.5rem;
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
  header.header-athena {
    background-image: url(../assets/images/aboutus/header_Athena.png);
    background-size: 100% 100%;
    margin-top: 5.143rem;
    padding-top: 4.65rem;
    padding-bottom: 4.65rem;
  }
  header.header-ai-design {
    background-image: url(../assets/images/aboutus/header_Ai-design.png);
    background-size: 100% 100%;
    margin-top: 4.5rem;
    padding-top: 4.65rem;
    padding-bottom: 4.65rem;
  }
  header.header-product {
    background-image: url(../assets/images/aboutus/header_Product.png);
    background-size: 100% 100%;
    margin-top: 4.5rem;
    padding-top: 4.65rem;
    padding-bottom: 4.65rem;
  }
  header.header-contact-us {
    background-image: url(../assets/images/aboutus/header_Contact-us.png);
    background-size: 100% 100%;
    margin-top: 4.5rem;
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
  header.header-aboutus .header-aboutus-heading,
  header.header-ai-design .header-ai-design-heading,
  header.header-athena .heading,
  header.header-product .header-product-heading,
  header.header-contact-us .heading {
    font-size: 2.857142857142857rem;
    margin: 1rem 0;
    line-height: 3.25rem;
  }
  header.header-aboutus .header-aboutus-content span,
  header.header-ai-design .header-ai-design-content span,
  header.header-athena .content span,
  header.header-product .header-product-content span,
  header.header-contact-us .content span {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  header.header-aboutus .header-aboutus-sticky {
    border: 2px solid rgba(255, 255, 255, 0.87);
  }
  //
  #mainNav .grid {
    margin: 0;
    padding: 0.75rem 1rem !important;
  }
  #mainNav .navbar-toggler {
    background-color: rgba(0, 0, 0, 0);
  }
  #mainNav .navbar-brand img {
    height: 1.3rem;
  }
  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
  // END NAV
  .react-multiple-carousel__arrow--right {
    left: 80px;
  }
  // HOMEPAGE
  video {
    height: 632px;
  }
  header.masthead {
    top: 164px;
    .grid {
      margin: 0;
      .masthead-sticky {
        width: 11.785714285714286rem;
        border: 2px solid rgba(255, 255, 255, 0.87);
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      }
      .masthead-subheading {
        font-size: 1.4285714285714286rem;
        width: 58%;
      }
      .masthead-heading {
        font-size: 2.4285714285714284rem;
      }
    }
  }
  .grid {
    margin: 0;
  }
  .aboutus .aboutus_information .aboutus_wrap {
    width: 100%;
  }
  .aboutus_sticky {
    .aboutus__title {
      font-size: 2.4rem;
      opacity: 1;
    }
    .aboutus__heading {
      font-size: 1.4285714285714286rem;
      line-height: 2.142857142857143rem;
    }
    .aboutus__content {
      width: 100%;
      font-size: 1.1rem;
      line-height: 1.7rem;
    }
  }
  .aboutus .aboutus__btn-contact .aboutus__btn-contact-content {
    font-size: 1rem;
  }
  .aboutus_image {
    .aboutus_image__img {
      float: right;
      width: 18rem;
      height: 22.785714285714285rem;
      border-radius: 55px;
      transform: none !important;
    }
    .aboutus_image__svg {
      width: 267px;
      height: 267px;
      left: 0;
      top: 60%;
    }
  }
  .character {
    max-width: 100%;
    flex-direction: row-reverse;
    .description {
      margin-top: 10rem;
      .title {
        font-size: 2.4285714285714284rem;
      }
      .content {
        min-width: 100%;
        font-size: 1.1rem;
        line-height: 1.7rem;
      }
      .mt-5 {
        margin-top: 1rem !important;
      }
      .about_us {
        i {
          margin-left: 8px;
        }
      }
    }
    .image {
      margin: 5.75rem -15px;
    }
    .vectorImage {
      // display: none;
      top: 20rem;
      img {
        height: 1000px;
      }
    }
  }
  .design_ai {
    .description {
      margin: 0 15px;
      .header {
        font-size: 1.4rem;
        line-height: 2.1rem;
        margin-top: 5.5625rem;
      }
      .title {
        font-size: 2.4rem;
        line-height: 3.15rem;
        margin-top: 1.1rem;
      }
      .content {
        font-size: 1.1rem;
        line-height: 1.7rem;
        margin-top: 1.1rem;
        padding: 0;
        .mt-5 {
          margin-top: 1rem !important;
        }
      }
      .design_ai-mobile {
        margin-top: 16px !important;
        margin-bottom: 2.932142857rem;
      }
    }
    .parent-image {
      height: 100%;
      margin-top: 50%;
      .image {
        bottom: -4.4rem;
      }
    }
  }
  .magnificent {
    padding-top: 100px;
    .title {
      width: 100% !important;
      line-height: 3rem;
      font-size: 2.6rem;
    }
  }
  .subTitle {
    font-size: 1.1rem !important;
    line-height: 1.7rem;
    letter-spacing: 0.15px;
    margin-top: 1.1rem;
  }
  .magnificent .magnificent__content {
    margin: 0;
    .viewAll {
      text-align: left;
      margin-top: 1.1rem;
      display: block;
      .viewAll__link {
        font-size: 1rem;
        line-height: 1.1rem;
        letter-spacing: 0.15px;
        display: block !important;
      }
    }
  }
  .page-section-research {
    background: linear-gradient(
        0deg,
        rgba(12, 28, 53, 0.8),
        rgba(12, 28, 53, 0.8)
      ),
      url("../assets/images/athena.png");
    background-position: 85% center;
    background-repeat: no-repeat;
  }
  .research {
    text-align: center;
    padding: 6.214285714285714rem 0;
    .description {
      .title {
        font-size: 2.4285714285714284rem;
        line-height: 3.1571428571428575rem;
      }
      .content {
        margin-top: 1.1rem;
        font-size: 1.1rem;
        line-height: 1.7rem;
        letter-spacing: 0.15px;
        width: 100%;
        margin-bottom: 1.1rem;
      }
    }
  }
  .trusted {
    padding-top: 120px;
    .trustedBy_title {
      margin-bottom: 0;
      .trusted_title {
        font-size: 2.4285714285714284rem;
        line-height: 3.1571428571428575rem;
      }
    }
    .trusted_left {
      .trustedBy_left__content {
        font-size: 1.1rem;
        line-height: 1.7rem;
        margin-top: 1.1rem;
        letter-spacing: 0.15px;
      }
    }
    .trusted_right {
      margin-top: 1.1rem;
      width: 70% !important;
      display: block;
      .trusted_button__btn {
        padding: 16px 24px;
      }
    }
  }
  .feedBack {
    padding-bottom: 80px;
    padding-top: 40px;
    .feedBackRight__sticky {
      display: none;
    }
    .feedBackRight__item-list {
      justify-content: center;
      .feedBackRight__item-wrap:last-child {
        justify-content: center;
      }
    }
  }
  .contact {
    padding: 40px 0;
    .contact_btn__submit {
      font-size: 0.8rem;
      line-height: 150%;
    }
  }
  .ourvision_tabpane-control {
    display: flex !important;
    width: 100%;
  }
  .page-section {
    margin: 0;
  }
  .page-section-research {
    background-image: url(../assets/images/Home-Athena-mobile.png) !important;
  }
  .c-dark-theme .btn-success {
    border-top-width: 0rem;
    border-bottom-width: 0rem;
    padding: 16px 0;
    font-size: 0.8rem;
  }
  // END HOMEPAGE
  // AI DESIGN
  header.header-ai-design {
    background-position: center center;
    .header-ai-design-sticky {
      border: 2px solid rgba(255, 255, 255, 0.87);
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      width: 76px;
    }
  }
  .information {
    margin: 0;
    padding: 40px 0;
    .information-sologan {
      font-size: 2.49rem;
      line-height: 130%;
    }
    .information-quote {
      font-size: 1.1rem;
      line-height: 1.7rem;
    }
  }
  .section-desingai {
    background-image: url(../assets/images/mobileeelip.png);
    background-size: 500px 2000px;
    background-position: 30% 122%;
  }
  .section-idea1 {
    padding-top: 80px;
    background-size: 600px 1400px;
    background-position: 130% 100%;
    .idea {
      padding-top: 0;
      padding-bottom: 0;
      .idea-top {
        height: 100%;
      }
      .idea-bottom {
        height: 300px !important;
        .idea-bottom-image {
          height: 300px;
          left: 0% !important;
          top: -24px !important;
        }
      }
    }
    margin-bottom: 32px;
  }
  .section-idea2 {
    margin-bottom: 32px;
    .idea {
      padding-bottom: 0;
      padding-top: 0;
      .idea-top {
        height: 100%;
      }
      .idea-bottom {
        height: 300px !important;
        .idea-bottom-image {
          height: 300px;
          left: 0% !important;
          top: -24px !important;
        }
      }
    }
  }
  .section-idea3 {
    margin-bottom: 32px;
    .idea {
      padding-bottom: 0;
      padding-top: 0;
      .idea-top {
        height: 100%;
      }
      .idea-bottom {
        height: 300px !important;
        .idea-bottom-image {
          height: 300px;
          left: 0% !important;
          top: -24px !important;
        }
      }
    }
  }
  .section-idea4 {
    margin-bottom: 32px;
    .idea {
      padding-bottom: 0;
      padding-top: 0;
      .idea-top {
        height: 100%;
      }
      .idea-bottom {
        height: 300px !important;
        .idea-bottom-image {
          height: 300px;
          left: 0% !important;
          top: -24px !important;
        }
      }
    }
  }
  .section-idea5 {
    padding-bottom: 180px;
    .idea {
      padding-bottom: 0;
      padding-top: 0;
      .idea-top {
        height: 100%;
      }
      .idea-bottom {
        height: 300px !important;
        .idea-bottom-image {
          height: 300px;
          left: 0% !important;
          top: -24px !important;
        }
      }
    }
  }
  .idea .designai .designai-main {
    width: 100%;
    height: 100%;
    .designai-button {
      padding: 16px 24px;
    }
  }
  // END AI DESIGN
  // PRODUCT
  header.header-product {
    .header-product-sticky {
      border: 2px solid rgba(255, 255, 255, 0.87);
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    }
    .header-product-heading {
      margin-top: 1.1rem;
      font-size: 2.857142857142857rem;
      line-height: 3.7142857142857144rem;
      margin-bottom: 0;
    }
    .header-product-content {
      font-size: 1.1rem;
      line-height: 1.7rem;
    }
  }
  .subheader-product {
    margin: 0;
    padding: 40px 0;
    p {
      font-size: 1.1rem;
      line-height: 1.7rem;
    }
  }
  .magnificent-products {
    padding-top: 92px;
    padding-bottom: 120px;
    .magnificent-products-title {
      margin: 0;
      font-size: 2.1285714285714284rem;
      line-height: 3.1571428571428575rem;
    }
    .magnificent-products-content {
      padding-top: 2.857142857142857rem;
    }
  }
  .magnificent-products-subcontent-list {
    .magnificent-products-subcontennt-background {
      width: 100% !important;
      height: 240px !important;
    }
  }
  .product-demo {
    text-align: center;
    .product-demo-title {
      font-size: 2.4285714285714284rem !important;
      line-height: 3.1571428571428575rem !important;
    }
  }
  .section-DemoProduct {
    background-image: url(../assets/images/product/Product-mobile.png);
  }
  // END PRODUCT
  //START ABOUT US
  //start c-body
  .whatbai {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .whatbai .whatbai-title h2 {
    font-size: 2.5rem;
  }
  .whatbai .whatbai-quote {
    margin: 0;
  }
  .whatbai .whatbai-quote .whatbai-quote-content {
    font-size: 1rem;
    padding-bottom: 2.5rem;
    color: rgba(255, 255, 255, 0.6);
  }
  .whatbai .whatbai-ceo {
    margin: 0;
    flex-wrap: wrap;
    padding: 2.5rem 0 0 0 !important;
  }
  .whatbai .whatbai-ceo .whatbai-ceo-left {
    margin: 0 !important;
  }
  .whatbai .whatbai-ceo .whatbai-ceo-right {
    margin: 0;
    padding: 2.5rem 0 0 0;
  }
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-right
    .whatbai-ceo-right-infomation
    .whatbai-ceo-right-infomation__content,
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-left
    .whatbai-ceo-left-infomation
    .whatbai-ceo-left-infomation__content {
    font-size: 1rem;
    font-weight: 300;
    max-width: 100%;
    color: rgba(255, 255, 255, 0.845);
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .ourvision {
    padding-top: 7.5rem;
    padding-bottom: 2.5rem;
    margin: 0;
    .ourvision-questions {
      margin-top: 0;
      .ourvision-questions__list {
        padding: 0 !important;
        .ourvision-questions__item {
          padding-left: 0 !important;
          text-align: center;
        }
        .active.ourvision-questions__item-link i {
          display: none !important;
        }
      }
      .tab-content {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.07),
            rgba(255, 255, 255, 0.07)
          ),
          #050915;
        margin-top: 20rem !important;
      }
    }
    .ourvision-quote {
      margin: 0;
    }
    .ourvision-quote p {
      font-size: 1rem;
      font-weight: 0;
      line-height: 2.2rem;
      color: rgba(255, 255, 255, 0.6);
      padding: 0 1.3rem;
    }
    .ourvision_tabpane {
      padding: 1rem !important;
      .ourvision_tabpane-title {
        padding-right: 0;
        text-align: initial;
        margin-top: 0;
      }
      .ourvision_tabpane-img-B {
        left: calc(100% / 4) !important;
      }
      .ourvision_tabpane-img-elip {
        top: -280px !important;
        left: calc(100% / 40) !important;
        width: 250px !important;
        opacity: 1 !important;
      }
      .ourvision_tabpane-content {
        margin: 2rem 0;
        font-size: 1rem;
        line-height: 1.625rem;
        overflow: scroll;
        height: 200px;
      }
    }
  }
  .control-button {
    display: block !important;
  }
  .grid {
    margin: 0;
  }
  .icon-img-up {
    padding-top: 16px;
  }
  .aboutus-activity {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    .aboutus-activity__title {
      margin: 0;
      font-size: 2.125rem;
      line-height: 130%;
    }
    .aboutus-activity__quote {
      margin: 1rem 0 0 0;
    }
    .aboutus-activity__quote p {
      font-size: 1rem;
      line-height: 1.625rem;
    }
    // .aboutus-activity__tabs {
    //   .about-us__tab-list {
    //     padding: 0 !important;
    //     justify-content: center;
    //   }
    //   .about-us__tab-item {
    //     .about-us__tab-item__link {
    //       padding: 8px 12px !important;
    //       font-size: 0.8rem !important;
    //     }
    //   }
    // }
    .aboutus-activity__tabs {
      display: none;
    }
    .aboutus-activity__tabs--mobile {
      display: block;
      overflow: hidden;
      .about-us__tab-list--mobile {
        overflow-x: unset;
        flex-wrap: nowrap;
      }
      .about-us__tab-item--mobile {
        .about-us__tab-item__link--mobile {
          width: max-content;
          color: #fff;
          opacity: 0.87;
        }
      }
    }
  }
  .tab-content > .active {
    margin-top: 2rem;
  }
  .tab-content-left,
  .tab-content-middle,
  .tab-content-right {
    .tab-content-left-title,
    .tab-content-middle-title,
    .tab-content-right-title {
      margin: 0;
      font-size: 1.25rem;
      line-height: 150%;
    }
    .tab-content-middle-quote,
    .tab-content-left-quote,
    .tab-content-right-quote {
      margin: 0;
      font-size: 1rem;
    }
    .tab-content-left-quote p {
      margin-top: 1.5rem;
      margin-bottom: 2.5rem;
    }
    .tab-content-middle-img-list,
    .tab-content-left-img-list,
    .tab-content-right-img-list {
      margin: 0;
      .tab-content-middle-img-item,
      .tab-content-left-img-item,
      .tab-content-right-img-item {
        background: none;
      }
    }
  }
  .tab-content-left--mobile,
  .tab-content-middle--mobile,
  .tab-content-right--mobile {
    .tab-content-left-title--mobile,
    .tab-content-middle-title--mobile,
    .tab-content-right-title--mobile {
      margin: 0;
      font-size: 1.25rem;
      line-height: 150%;
      font-weight: 700;
      opacity: 0.87;
      margin: 0 2rem;
    }
    .tab-content-left-quote--mobile,
    .tab-content-middle-quote--mobile,
    .tab-content-right-quote--mobile {
      padding-top: 24px;
      padding-bottom: 40px;
      opacity: 0.6;
    }
  }
  .item-img-right--mobile {
    padding-top: 21px;
  }
  .icon-img-down-left--mobile,
  .icon-img-down-right--mobile {
    padding-top: 6px !important;
  }
  .icon-img-down-left--mobile {
    padding-right: 10.5px;
  }
  .icon-img-down-right--mobile {
    padding-left: 10.5px;
  }
  // section-Product
  .Product-top,
  .Product-bottom {
    .Product-top__title,
    .Product-bottom__title {
      font-size: 2.125rem;
      line-height: 150%;
      padding-bottom: 1rem;
      margin: 0;
    }
  }
  .Product-top {
    margin: 0;
    padding: 2.75rem 0;
  }
  .Product-bottom {
    margin: 0;
    padding: 4.125rem 0;
  }
  .Product-top-img {
    background-image: url(../assets/images/aboutus/ProductTop-mobile.png);
    background-size: cover;
    width: 100%;
    height: 284px;
  }
  .Product-bottom-img-mobile {
    background-image: url(../assets/images/aboutus/ProductBottom-mobile.png);
    background-size: cover;
    width: 100%;
    height: 284px;
    margin-bottom: 1.25rem;
  }
  .go-to-top .icon {
    display: none;
  }
  // end section-Product
  //end c-body
  // start c-footer
  .c-footer {
    .nav {
      display: inline;
      text-align: center;
    }
  }
  //end c-footer
  //END ABOUT US
  // START CONTACT US
  //start nav
  //end nav
  //start contact1
  .contact1 {
    padding: 4rem 0;
    .contact-left {
      .contact-left-title {
        font-size: 1.25rem;
      }
      .content {
        padding-top: 1rem;
        padding-right: 0;
      }
      .contact-title-left__subtitle_content {
        font-size: 2.125rem;
        line-height: 130%;
        padding-right: 8rem;
      }
      .contact-title-left__content {
        p {
          font-size: 1.1rem;
          line-height: 1.7rem;
        }
      }
    }
  }
  //end contact1
  //END CONTACT US
  // START ATHENA
  // Start Header
  header.header-athena .heading {
    font-size: 40px;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  // End Header
  .athena-content {
    padding: 2.5rem 1rem;
  }
  //Start our
  .athena-ourfeatured .product {
    margin-bottom: 1rem;
    width: 96%;
    height: 290px;
  }
  .athena-ourfeatured .title {
    margin-bottom: 2rem;
    margin-top: 2rem;
    font-size: 34px;
  }
  // End Our
  // Start wanna
  .athena_wanna .description {
    text-align: center;
  }
  .athena_wanna .description .content {
    font-size: 34px;
    width: 100%;
  }
  // End wanna
  .section-Contact {
    background: linear-gradient(
        90deg,
        rgba(18, 23, 40, 0.57) 47.4%,
        rgba(18, 23, 40, 0) 83.85%
      ),
      url("../assets/images/athena/bg_wanna-mobile.png"), #121728;
    background-repeat: no-repeat;
    background-size: cover;
  }
  //Start GET IN
  .contact .contact-left {
    width: 100%;
  }
  .contact .contact-left .contact-left-title {
    font-size: 1.25rem;
  }
  .contact .contact-left .contact-title-left__subtitle {
    font-size: 2.1rem;
  }
  .contact .contact-left .contact-title-left__content {
    opacity: 0.87;
    font-weight: 400;
  }
  .athena_wanna {
    padding: 7% 0%;
    text-align: center;
    .description {
      position: initial;
    }
  }
  //END Get in
  // Start footer
  .footer {
    text-align: center;
    height: 100%;
    .logo {
      order: 1;
    }
    .menu {
      .nav {
        flex-direction: column;
      }
      order: 2;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }
    .copyright {
      text-align: center;
      order: 3;
    }
  }
  // End footer
  // END ATHENA
  //IMPRINT
  header.header-imprint {
    .header-imprint-content {
      position: relative;
      width: 100%;
      height: 100px;
      // .header-imprint-circle {
      //   z-index: 1000;
      //   background-image: radial-gradient(
      //     circle 63px at 50% 0,
      //     transparent 700px,
      //     #0a0b18 50px
      //   );
      //   background-image: -webkit-radial-gradient(
      //     circle 63px at 50% 0,
      //     transparent 700px,
      //     #0a0b18 50px
      //   );
      // }
    }
    .header-imprint-circle-background {
      height: 140px !important;
      width: 140px !important;
      top: -31px !important;
      left: calc(50% - 70px) !important;
      .header-imprint-circle-background-image {
        width: 47px;
        height: 56px;
      }
    }
  }
  .imprint-content {
    margin-top: 64px;
    margin-bottom: 54px;
    .title {
      font-size: 34px;
      margin-bottom: 32px;
    }
    .image-mobile {
      display: inline;
    }
    .image-mobile img {
      width: 100%;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      justify-content: space-between;
      margin-top: 32px;
      height: auto;
      .pham {
        font-weight: 700;
        font-size: 20px;
        color: rgba(255, 255, 255, 0.87);
        line-height: 150%;
      }
      .am-falder {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.87);
      }
      .content-1 {
        font-size: 16px;
        opacity: 0.87;
        .change-color {
          font-weight: 700;
          color: #4fb978;
        }
      }
      .content-2 {
        font-size: 16px;
        opacity: 0.87;
        width: 76%;
      }
    }
  }
  .imprint-image {
    margin-top: 110px;
    display: none;
  }
  header.header-imprint {
    background-image: url(../assets/images/imprint/headerImprint-mobile.png);
    padding-top: 0;
    height: 375px !important;
  }
  header.header-imprint .header-imprint-content .header-imprint-circle {
    bottom: -273px;
  }
  //END IMPRINT
  .aboutus .aboutus_information {
    height: 540px;
  }
  .aboutus {
    .aboutus_sticky {
      position: inherit !important;
    }
  }
  // .homepage-page {
  //   display: none;
  // }
  // .mobile-vectorImage {
  //   display: block !important;
  // }
}

@media (max-width: 320px) {
  .popup--Video {
    height: 450px !important;
  }
  .popup-right .content {
    height: 115px !important;
  }
  .popup-modal .button-mobile {
    margin: 0 !important;
    padding: 5% 28% !important;
  }
  .popup-modal {
    margin-top: 36px !important;
  }
  .ourvision {
    .ourvision_tabpane {
      .ourvision_tabpane-img-B {
        top: -240px;
        left: 25px;
      }
      .ourvision_tabpane-img-elip {
        top: -340px;
        left: -50px;
        opacity: 1;
      }
      .ourvision_tabpane-content {
        margin: 2rem 0;
        font-size: 1rem;
        line-height: 1.625rem;
        text-align: initial;
      }
    }
  }
  .formSubmitting {
    width: 70%;
  }
  .research {
    .title {
      font-size: 2.228571rem !important;
    }
  }
  .magnificent {
    .title {
      font-size: 2.6rem !important;
    }
  }
  .modal__wrapper {
    width: 300px !important;
  }
}

@media (min-width: 320px) and (max-width: 740px) and (orientation: landscape) {
  .modal-body {
    padding: 0.8rem !important;
  }

  header.header-aboutus {
    background-image: url(../assets/images/header-ab-us-tablet.png);
    margin-top: 72px;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  header.header-ai-design {
    background-image: url(../assets/images/header-ai-tablet.png);
    margin-top: 72px;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  header.header-product {
    background-image: url(../assets/images/header-product-tablet.png);
    margin-top: 72px;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  header.header-athena {
    background-image: url(../assets/images/header_athena.png);
    margin-top: 72px;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  header.header-contact-us {
    background-image: url(../assets/images/header-ct-us-tablet.png);
    margin-top: 72px;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .c-dark-theme .btn-success {
    border-top-width: 0rem;
    border-bottom-width: 0rem;
    padding: 16px 0;
    font-size: 0.8rem;
  }
  .contact .formSubmitting {
    margin-top: 16px;
  }
  .grid {
    margin: 0;
  }
  .whatbai .whatbai-quote {
    margin: 0;
  }
  .whatbai-ceo-left {
    .whatbai-ceo-left-image {
      img {
        width: 280px !important;
      }
    }
  }
  .whatbai-ceo-right {
    .whatbai-ceo-right-image {
      img {
        width: 280px !important;
      }
    }
  }
  .aboutus-activity .aboutus-activity__title {
    margin-left: 0;
    margin-right: 0;
  }
  .ourvision {
    .ourvision-quote {
      margin-left: 0;
      margin-right: 0;
    }
    .ourvision-questions__list {
      text-align: center;
      .ourvision-questions__item {
        padding-left: 0 !important;
      }
    }
    .ourvision_tabpane {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      .ourvision_tabpane-title {
        margin-top: 140px;
      }
      .ourvision_tabpane-img-elip {
        top: -300px;
        left: 0px;
        // top: -260px;
        // left: 158px;
      }
      .ourvision_tabpane-img-B {
        top: -234px;
        left: 50px;
        width: 150px;
      }
    }
  }
  .aboutus-activity {
    .aboutus-activity__quote {
      margin: 1.1rem 0;
    }
    .aboutus-activity__title {
      margin: 0;
    }
  }
  .about-us__tab-list .about-us__tab-item .about-us__tab-item__link {
    font-size: 0.8rem !important;
  }
  .tab-content-left .tab-content-left-title,
  .tab-content-middle .tab-content-middle-title,
  .tab-content-right .tab-content-right-title {
    font-size: 1.5rem;
    padding: 2.5rem 3.1875rem 0 3.1875rem;
    line-height: 1.95rem;
    margin: 0;
  }
  .tab-content-left .tab-content-left-quote,
  .tab-content-middle .tab-content-middle-quote,
  .tab-content-right .tab-content-right-quote {
    margin: 0;
  }
  .tab-content-left .tab-content-left-quote p,
  .tab-content-middle .tab-content-middle-quote p,
  .tab-content-right .tab-content-right-quote p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
    padding: 1.5rem 3.5625rem 0 3.5625rem;
  }
  .tab-content-left .tab-content-left-img-list,
  .tab-content-middle .tab-content-middle-img-list,
  .tab-content-right .tab-content-right-img-list {
    margin-left: 0;
    margin-right: 0;
    margin-top: 2.5rem;
    padding: 0 1rem;
  }
  .item-img-right {
    padding: 0 1rem 0 5px;
  }
  .icon-img-down-left {
    padding-top: 5px !important;
    padding-right: 10px;
  }
  .icon-img-down-right {
    padding-top: 5px !important;
    padding-left: 10px;
  }
  .Product-top-img {
    width: 100%;
    height: 260px;
  }
  header.header-ai-design {
    .header-ai-design-heading {
      font-size: 2rem;
    }
  }
  .information {
    margin: 0;
    .information-sologan {
      font-size: 2rem;
    }
  }
  .section-desingai {
    text-align: center;
  }
  .subheader-product {
    margin: 0;
  }
  .magnificent-products {
    .magnificent-products-title {
      margin: 0;
    }
  }
  .navbar__mobile-link {
    margin-top: 5px;
  }
  .navbar__mobile-logo {
    img {
      width: 100%;
    }
    bottom: 6 !important;
    right: 0;
  }
  .top-img {
    width: 100%;
    height: 284px;
  }
  .Product-bottom-img {
    width: 100%;
    height: 284px;
    order: 2;
  }
  header.header-ai-design {
    .header-ai-design-heading {
      font-size: 2rem;
    }
  }
  .information {
    margin: 0;
    .information-sologan {
      font-size: 2rem;
    }
  }
  .section-desingai {
    text-align: center;
  }
  .subheader-product {
    margin: 0;
  }
  .magnificent-products {
    .magnificent-products-title {
      margin: 0;
    }
  }
  .navbar__mobile-link {
    margin-top: 5px;
  }
  .navbar__mobile-logo {
    img {
      width: 100%;
    }
    bottom: 6 !important;
    right: 0;
  }
  .aboutus_image .aboutus_image__svg {
    height: 18rem;
    width: 18rem;
    left: -3rem;
    bottom: 13rem;
  }
  header.masthead {
    top: 20%;
    .masthead-subheading {
      width: 70%;
    }
    .masthead-heading {
      font-size: 1.5rem;
    }
    .mt-5,
    .my-5 {
      margin-top: 0 !important;
    }
  }
  .feedBackRight__sticky {
    display: none;
  }
  //HOME
  .aboutus .aboutus__heading,
  .aboutus .aboutus__content {
    width: 65%;
  }
  .character .description {
    margin-top: 5.375rem;
  }
  //END HOME
  //ABOUT-US
  //whatbai
  .whatbai .whatbai-ceo {
    padding: 0 2rem;
  }
  //ourvision
  .ourvision .ourvision-questions {
    margin-top: 10rem;
  }
  .item-img-left img {
    height: 100%;
  }
  //END ABOUT-US
  //ABOUT-US
  //whatbai
  .whatbai .whatbai-ceo {
    padding: 0 2rem;
  }
  //ourvision
  .ourvision .ourvision-questions {
    margin-top: 8rem;
  }
  //END ABOUT-US
  // .idea .designai .designai-main {
  //   width: 100%;
  //   height: 470px;
  // }
  .section-idea1 .idea .idea-bottom .idea-bottom-image {
    width: 400px !important;
  }
  .section-idea2 .idea .idea-bottom .idea-bottom-image {
    width: 400px !important;
  }
  .section-idea3 .idea .idea-bottom .idea-bottom-image {
    width: 400px !important;
  }
  .section-idea4 .idea .idea-bottom .idea-bottom-image {
    width: 400px !important;
  }
  .section-idea5 .idea .idea-bottom .idea-bottom-image {
    width: 400px !important;
  }
  //END AI DESIGN
  //ATHENA
  .athena_wanna .description .content {
    width: 100%;
  }
  //END ATHENA
  // AI DESIGN
  .idea .designai .designai-main {
    padding: 20px 40px;
  }
  .idea .designai .designai-main .designai-content {
    font-size: 1rem;
  }
  .idea .designai .designai-main .designai-button {
    font-size: 1rem;
  }
  // .idea .designai .designai-main {
  //   width: 100%;
  //   height: 470px;
  // }
  .section-idea1 .idea .idea-bottom .idea-bottom-image {
    width: 400px !important;
  }
  .section-idea2 .idea .idea-bottom .idea-bottom-image {
    width: 400px !important;
  }
  .section-idea3 .idea .idea-bottom .idea-bottom-image {
    width: 400px !important;
  }
  .section-idea4 .idea .idea-bottom .idea-bottom-image {
    width: 400px !important;
  }
  .section-idea5 .idea .idea-bottom .idea-bottom-image {
    width: 400px !important;
  }
  //END AI DESIGN
  //START IMPRINT
  .imprint-content {
    margin: 0;
  }
  .no-gutters {
    padding: 2rem 1rem 2.5rem 1rem;
  }
  .imprint-content .title {
    font-size: 2.5rem;
  }
  .imprint-content .content {
    margin-top: 1rem;
  }
  .imprint-content .content .pham {
    font-size: 1rem;
    font-weight: normal;
    height: auto;
  }
  .imprint-content .content .am-falder {
    width: 130px;
    height: auto;
  }
  .imprint-image {
    display: none;
  }
  .imprint-image-tablet {
    display: block;
    padding-top: 6rem;
  }
  .imprint-image-tablet img {
    width: 100%;
  }
  .imprint-content .content .content-1 {
    width: 600px;
    margin-top: 1rem;
  }
  .imprint-content .content .content-2 {
    padding-right: 16rem;
    width: 600px;
    margin-top: 1rem;
  }
  //END imprint
  //footer
  .footer {
    text-align: center;
    height: 100%;
    .logo {
      order: 1;
      padding-top: 1rem;
    }
    .menu {
      .footer-menu-col {
        padding: 0 !important;
      }
      .nav {
        flex-direction: row;
        justify-content: center;
      }
      order: 2;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
    .copyright {
      text-align: center;
      order: 3;
    }
  }
}

@media only screen and(max-width: 568px) {
  //STATR NAV
  // #mainNav {
  //   max-width: 100%;
  //   display: contents;
  // }
  //popup-video
  header.header-policy {
    .header-content {
      width: 345px;
      .title-cookies-policy {
        font-size: 45px;
      }
      .header-policy-navigation {
        .home-link {
        }
      }
      .header-policy-content {
      }
    }
  }
  .policy {
    flex-direction: column;
    .policy-menu {
      font-size: 16px;
      height: 300px;
      margin-bottom: 21%;
      .policy-menu-title {
      }
      .policy-menu-tab-menu {
        font-size: 16px;
        line-height: 150%;
        .policy_underlined {
        }
      }
      .policy-menu-tab-menu:focus {
      }
    }
    .policy-content {
      .policy-conetent-item {
        .policy-content-title {
          font-size: 24px;
          line-height: 130%;
        }
        .policy-content-content {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
  .popup-modal {
    margin-top: 72px;
    padding-top: 20px;
  }
  .popup-modal .popup-video {
    height: 192px !important;
  }
  .popup-right .title {
    font-size: 20px;
    margin: 0;
    margin-top: 20px;
  }
  .popup-right .content {
    height: 150px;
    margin: 0;
    margin-top: 16px;
    overflow: scroll;
    text-overflow: ellipsis;
    display: -webkit-box;
    // -webkit-line-clamp: 6;
    // -webkit-box-orient: vertical;
  }
  .popup-modal .button-close {
    right: 0;
    top: -8%;
  }
  .popup-modal .button-next {
    display: none;
  }
  .popup-modal .button-prev {
    display: none;
  }
  .popup-modal {
    .button-mobile {
      display: flex;
      justify-content: space-around;
      font-weight: 900;
      font-size: 32px;
      line-height: 37px;
      color: rgba(255, 255, 255, 0.6);
      padding: 7% 28%;
    }
  }
  .popup--Video {
    width: 100% !important;
    height: 566px;
    margin: 0 auto;
  }
  //end popup video
  //chance header imgage
  header.header-aboutus {
    background-image: url(../assets/images/aboutus/header_about-us.png);
    background-size: 100% 100%;
    margin-top: 4.5rem;
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
  header.header-athena {
    background-image: url(../assets/images/aboutus/header_Athena.png);
    background-size: 100% 100%;
    margin-top: 5.143rem;
    padding-top: 4.65rem;
    padding-bottom: 4.65rem;
  }
  header.header-ai-design {
    background-image: url(../assets/images/aboutus/header_Ai-design.png);
    background-size: 100% 100%;
    margin-top: 4.5rem;
    padding-top: 4.65rem;
    padding-bottom: 4.65rem;
  }
  header.header-product {
    background-image: url(../assets/images/aboutus/header_Product.png);
    background-size: 100% 100%;
    margin-top: 4.5rem;
    padding-top: 4.65rem;
    padding-bottom: 4.65rem;
  }
  header.header-contact-us {
    background-image: url(../assets/images/aboutus/header_Contact-us.png);
    background-size: 100% 100%;
    margin-top: 4.5rem;
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
  header.header-aboutus .header-aboutus-heading,
  header.header-ai-design .header-ai-design-heading,
  header.header-athena .heading,
  header.header-product .header-product-heading,
  header.header-contact-us .heading {
    font-size: 2.857142857142857rem;
    margin: 1rem 0;
    line-height: 3.25rem;
  }
  header.header-aboutus .header-aboutus-content span,
  header.header-ai-design .header-ai-design-content span,
  header.header-athena .content span,
  header.header-product .header-product-content span,
  header.header-contact-us .content span {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  header.header-aboutus .header-aboutus-sticky {
    border: 2px solid rgba(255, 255, 255, 0.87);
  }
  //
  #mainNav .grid {
    margin: 0;
    padding: 0.75rem 1rem !important;
  }
  #mainNav .navbar-toggler {
    background-color: rgba(0, 0, 0, 0);
  }
  #mainNav .navbar-brand img {
    height: 1.3rem;
  }
  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
  // END NAV
  // HOMEPAGE
  video {
    height: 632px;
  }
  .video_header .overlay {
    margin: 0;
  }
  header.masthead {
    top: 164px;
    .grid {
      margin: 0;
      .masthead-sticky {
        width: 11.785714285714286rem;
        border: 2px solid rgba(255, 255, 255, 0.87);
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      }
      .masthead-subheading {
        font-size: 1.4285714285714286rem;
        width: 58%;
      }
      .masthead-heading {
        font-size: 2.4285714285714284rem;
      }
    }
  }
  .grid {
    margin: 0;
  }
  .aboutus .aboutus_information .aboutus_wrap {
    width: 100%;
  }
  .aboutus_sticky {
    .aboutus__title {
      font-size: 2.4rem;
      opacity: 1;
    }
    .aboutus__heading {
      font-size: 1.4285714285714286rem;
      line-height: 2.142857142857143rem;
    }
    .aboutus__content {
      width: 100%;
      font-size: 1.1rem;
      line-height: 1.7rem;
    }
  }
  .aboutus .aboutus__btn-contact .aboutus__btn-contact-content {
    font-size: 1rem;
  }
  .aboutus_image {
    .aboutus_image__img {
      float: right;
      width: 18rem;
      height: 22.785714285714285rem;
      border-radius: 55px;
      transform: none !important;
    }
    .aboutus_image__svg {
      width: 267px;
      height: 267px;
      left: 0;
      top: 60%;
    }
  }
  .character {
    max-width: 100%;
    flex-direction: row-reverse;
    .description {
      margin-top: 10rem;
      .title {
        font-size: 2.4285714285714284rem;
      }
      .content {
        min-width: 100%;
        font-size: 1.1rem;
        line-height: 1.7rem;
      }
      .about_us {
        i {
          margin-left: 8px;
        }
      }
    }
    .image {
      margin: 2.75rem -15px;
    }
    .vectorImage {
      // display: none;
      top: 26rem;
    }
  }
  .design_ai {
    .description {
      margin: 0 15px;
      .header {
        font-size: 1.4rem;
        line-height: 2.1rem;
      }
      .title {
        font-size: 2.4rem;
        line-height: 3.15rem;
        margin-top: 1.1rem;
      }
      .content {
        font-size: 1.1rem;
        line-height: 1.7rem;
        margin-top: 1.1rem;
      }
      .design_ai-mobile {
        margin-top: 16px !important;
        margin-bottom: 2.932142857rem;
      }
    }
    .parent-image {
      height: 100%;
      margin-top: 50%;
      .image {
        bottom: -4.4rem;
      }
    }
  }
  .slider-image-item {
    padding: 60px 0 20px 10px !important;
  }
  .react-multiple-carousel__arrow--right {
    right: 0 !important;
    top: 10px;
    left: 20%;
    width: 43px;
  }
  .react-multiple-carousel__arrow--left {
    top: 10px;
    left: 0;
  }
  .magnificent {
    .title {
      min-width: 100%;
    }
  }
  .subTitle {
    font-size: 1.1rem;
    line-height: 1.7rem;
    letter-spacing: 0.15px;
    margin-top: 1.1rem;
  }
  .magnificent .magnificent__content .viewAll {
    text-align: left;
    margin-top: 1.1rem;
    .viewAll__link {
      font-size: 1rem;
      line-height: 1.1rem;
      letter-spacing: 0.15px;
    }
  }
  .page-section-research {
    background: linear-gradient(
        0deg,
        rgba(12, 28, 53, 0.8),
        rgba(12, 28, 53, 0.8)
      ),
      url("../assets/images/athena.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -120px;
  }
  .feedBack .feedBackLeft .feedBackLeft__introduce {
    width: 100%;
  }
  .research {
    text-align: center;
    padding: 6.214285714285714rem 0;
    .description {
      .title {
        font-size: 2.4285714285714284rem;
        line-height: 3.1571428571428575rem;
      }
      .content {
        margin-top: 1.1rem;
        font-size: 1.1rem;
        line-height: 1.7rem;
        letter-spacing: 0.15px;
        width: 100%;
        margin-top: 2%;
      }
    }
  }
  .trusted {
    .trustedBy_title {
      margin-bottom: 0;
      .trusted_title {
        font-size: 2.4285714285714284rem;
        line-height: 3.1571428571428575rem;
      }
    }
    .trusted_left {
      .trustedBy_left__content {
        font-size: 1.1rem;
        line-height: 1.7rem;
        margin-top: 1.1rem;
        letter-spacing: 0.15px;
      }
    }
    .trusted_right {
      margin-top: 1.1rem;
      width: 80%;
    }
  }
  .feedBack {
    .feedBackRight__sticky {
      display: none;
    }
    .feedBackRight__item-list {
      justify-content: center;
      .feedBackRight__item-wrap:last-child {
        justify-content: center;
      }
    }
  }
  .ourvision_tabpane-control {
    display: flex !important;
    width: 100%;
  }
  .formSubmitting {
    margin-top: 16px;
    width: 56%;
  }
  // END HOMEPAGE
  // AI DESIGN
  header.header-ai-design {
    background-position: center center;
    .header-ai-design-sticky {
      border: 2px solid rgba(255, 255, 255, 0.87);
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      width: 76px;
    }
  }
  .information {
    margin: 0;
    padding: 40px 0;
    .information-sologan {
      font-size: 2.49rem;
      line-height: 130%;
    }
    .information-quote {
      font-size: 1.1rem;
      line-height: 1.7rem;
    }
  }
  .section-desingai {
    background-size: 350px 2900px;
    background-position: 70% 140%;
  }
  .section-idea1 {
    padding-top: 80px;
    background-size: 1000px 2500px;
    background-position: right bottom;
    .idea {
      padding-top: 0;
      padding-bottom: 0;
      .idea-top {
        height: 100%;
      }
      .idea-bottom {
        height: 300px !important;
        .idea-bottom-image {
          height: 300px;
          left: 0% !important;
          top: -24px !important;
        }
      }
    }
    margin-bottom: 32px;
  }
  .section-idea2 {
    margin-bottom: 32px;
    .idea {
      padding-bottom: 0;
      padding-top: 0;
      .idea-top {
        height: 100%;
      }
      .idea-bottom {
        height: 300px !important;
        .idea-bottom-image {
          height: 300px;
          left: 0% !important;
          top: -24px !important;
        }
      }
    }
  }
  .section-idea3 {
    margin-bottom: 32px;
    .idea {
      padding-bottom: 0;
      padding-top: 0;
      .idea-top {
        height: 100%;
      }
      .idea-bottom {
        height: 300px !important;
        .idea-bottom-image {
          height: 300px;
          left: 0% !important;
          top: -24px !important;
        }
      }
    }
  }
  .section-idea4 {
    margin-bottom: 32px;
    .idea {
      padding-bottom: 0;
      padding-top: 0;
      .idea-top {
        height: 100%;
      }
      .idea-bottom {
        height: 300px !important;
        .idea-bottom-image {
          height: 300px;
          left: 0% !important;
          top: -24px !important;
        }
      }
    }
  }
  .section-idea5 {
    padding-bottom: 120px;
    .idea {
      padding-bottom: 0;
      padding-top: 0;
      .idea-top {
        height: 100%;
      }
      .idea-bottom {
        height: 300px !important;
        .idea-bottom-image {
          height: 300px;
          left: 0% !important;
          top: -24px !important;
        }
      }
    }
  }
  .idea .designai .designai-main {
    width: 100%;
    height: 100%;
  }
  // END AI DESIGN
  // PRODUCT
  header.header-product {
    .header-product-sticky {
      border: 2px solid rgba(255, 255, 255, 0.87);
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    }
    .header-product-heading {
      margin-top: 1.1rem;
      font-size: 2.857142857142857rem;
      line-height: 3.7142857142857144rem;
      margin-bottom: 0;
    }
    .header-product-content {
      font-size: 1.1rem;
      line-height: 1.7rem;
    }
  }
  .subheader-product {
    margin: 0;
    padding: 40px 0;
    p {
      font-size: 1.1rem;
      line-height: 1.7rem;
    }
  }
  .magnificent-products {
    padding-top: 92px;
    padding-bottom: 120px;
    .magnificent-products-title {
      margin: 0;
      font-size: 2.1285714285714284rem;
      line-height: 3.1571428571428575rem;
    }
    .magnificent-products-content {
      padding-top: 2.857142857142857rem;
    }
  }
  .magnificent-products-subcontent-list {
    .magnificent-products-subcontennt-background {
      width: 100% !important;
      height: 240px !important;
    }
  }
  .product-demo {
    text-align: center;
    .product-demo-title {
      font-size: 2.4285714285714284rem !important;
      line-height: 3.1571428571428575rem !important;
    }
    .product-demo__button {
      border-left-width: 1rem;
      border-right-width: 1rem;
      span {
        font-size: 0.8rem;
        line-height: 150%;
      }
    }
  }
  .popup-right {
    .popup-right__subtitle {
      margin: 16px 0 0 0;
    }
    .popup-right__intro {
      margin: 16px 0 0 0;
    }
  }
  // END PRODUCT
  //START ABOUT US
  //start c-body
  .whatbai {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .whatbai .whatbai-title h2 {
    font-size: 2.5rem;
  }
  .whatbai .whatbai-quote {
    margin: 0;
  }
  .whatbai .whatbai-quote .whatbai-quote-content {
    font-size: 1rem;
    padding-bottom: 2.5rem;
    color: rgba(255, 255, 255, 0.6);
  }
  .whatbai .whatbai-ceo {
    margin: 0;
  }
  .whatbai .whatbai-ceo .whatbai-ceo-left {
    margin: 0;
    width: 28rem;
    margin-right: 20px;
  }
  .whatbai .whatbai-ceo .whatbai-ceo-right {
    margin: 0;
  }
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-right
    .whatbai-ceo-right-infomation
    .whatbai-ceo-right-infomation__content,
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-left
    .whatbai-ceo-left-infomation
    .whatbai-ceo-left-infomation__content {
    font-size: 1rem;
    font-weight: 300;
    max-width: 100%;
    color: rgba(255, 255, 255, 0.845);
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .ourvision {
    padding-top: 7.5rem;
    padding-bottom: 2.5rem;
    margin: 0;
    .ourvision-questions {
      margin-top: 0;
      .ourvision_part-right {
        background: none;
      }
      .ourvision-questions__list {
        padding: 0 !important;
        .ourvision-questions__item {
          padding-left: 0 !important;
          text-align: center;
        }
        .active.ourvision-questions__item-link i {
          display: none !important;
        }
      }
      .tab-content {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.07),
            rgba(255, 255, 255, 0.07)
          ),
          #050915;
        margin-top: 20rem;
      }
    }
    .ourvision-quote {
      margin: 0;
      margin-bottom: 40px;
    }
    .ourvision-quote p {
      font-size: 1rem;
      font-weight: 0;
      line-height: 2.2rem;
      color: rgba(255, 255, 255, 0.6);
    }
    .ourvision_tabpane {
      padding: 7rem 1rem 1rem 1rem !important;
      .ourvision_tabpane-title {
        padding-right: 5rem;
        text-align: initial;
        margin-top: 0;
      }
      .ourvision_tabpane-img-B {
        top: -212px;
        left: calc(100% / 3);
        width: 220px;
        height: 290px;
      }
      .ourvision_tabpane-img-elip {
        top: -270px;
        left: 60px;
        opacity: 0.87;
        width: 250px;
      }
      .ourvision_tabpane-content {
        margin: 2rem 0;
        font-size: 1rem;
        line-height: 1.625rem;
        text-align: initial;
      }
    }
  }
  .ourvision .ourvision_tabpane-control {
    margin: 0;
    padding-bottom: 40px;
    padding-left: 16px;
  }
  .control-button {
    display: block !important;
  }
  .grid {
    margin: 0;
  }
  .icon-img-up {
    padding-top: 16px;
  }
  .aboutus-activity {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    .aboutus-activity__title {
      margin: 0;
      font-size: 2.125rem;
      line-height: 130%;
      opacity: 0.87;
    }
    .aboutus-activity__quote {
      margin: 1rem 0 0 0;
    }
    .aboutus-activity__quote p {
      font-size: 1rem;
      line-height: 1.625rem;
    }
    .aboutus-activity__tabs {
      .about-us__tab-list {
        padding: 0 !important;
        justify-content: center;
      }
      .about-us__tab-item {
        .about-us__tab-item__link {
          padding: 8px 12px !important;
          font-size: 0.8rem !important;
        }
      }
      // .about-us__tab-list .about-us__tab-item:last-child {
      //   .about-us__tab-item__link {
      //     display: none;
      //   }
      // }
    }
  }
  .tab-content > .active {
    margin-top: 2rem;
  }
  .tab-content-left,
  .tab-content-middle,
  .tab-content-right {
    .tab-content-left-title,
    .tab-content-middle-title,
    .tab-content-right-title {
      margin: 0;
      font-size: 1.25rem;
      line-height: 150%;
    }
    .tab-content-middle-quote,
    .tab-content-left-quote,
    .tab-content-right-quote {
      margin: 0;
      font-size: 1rem;
    }
    .tab-content-left-quote p {
      margin-top: 1.5rem;
      margin-bottom: 2.5rem;
    }
    .tab-content-middle-img-list,
    .tab-content-left-img-list,
    .tab-content-right-img-list {
      margin: 0;
      .tab-content-middle-img-item,
      .tab-content-left-img-item,
      .tab-content-right-img-item {
        background: none;
      }
    }
  }
  // section-Product
  .Product-top,
  .Product-bottom {
    .Product-top__title,
    .Product-bottom__title {
      font-size: 2.125rem;
      line-height: 150%;
      padding-bottom: 1rem;
      margin: 0;
    }
  }
  .Product-top {
    margin: 0;
    padding: 2.75rem 0;
  }
  .Product-bottom {
    margin: 0;
    padding: 4.125rem 0;
  }
  .Product-top-img {
    background-image: url(../assets/images/aboutus/ProductTop-mobile.png);
    background-size: cover;
    width: 100%;
    height: 284px;
  }
  .Product-bottom-img {
    display: none;
  }
  .Product-bottom-img-mobile {
    background-image: url(../assets/images/aboutus/ProductBottom-mobile.png);
    background-size: cover;
    width: 100%;
    height: 284px;
    margin-bottom: 1.25rem;
  }
  .go-to-top .icon {
    display: none;
  }
  // end section-Product
  //end c-body
  // start c-footer
  .c-footer {
    .nav {
      display: inline;
      text-align: center;
    }
  }
  //end c-footer
  //END ABOUT US
  // START CONTACT US
  //start nav
  //end nav
  //start contact1
  .contact1 {
    padding: 4rem 0;
    .contact-left {
      .contact-left-title {
        font-size: 1.25rem;
      }
      .content {
        padding-top: 1rem;
        padding-right: 0;
      }
      .contact-title-left__subtitle_content {
        font-size: 2.125rem;
        line-height: 130%;
        padding-right: 8rem;
      }
      .contact-title-left__content {
        p {
          font-size: 1.1rem;
          line-height: 1.7rem;
        }
      }
    }
  }
  .contact1 .contact-left .contact-title-left__subtitle {
    width: 100%;
  }
  //end contact1
  //END CONTACT US
  // START ATHENA
  // Start Header
  header.header-athena .heading {
    font-size: 37px;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  // End Header
  .athena-content {
    padding: 2.5rem 1rem;
  }
  //Start our
  .athena-ourfeatured .product {
    margin-bottom: 1rem;
    width: 96%;
    height: 290px;
  }
  .athena-ourfeatured .title {
    margin-bottom: 2rem;
    margin-top: 2rem;
    font-size: 33px;
  }
  // End Our
  // Start wanna
  .athena_wanna {
    padding: 0;
  }
  .athena_wanna .description {
    text-align: center;
    padding-top: 80px;
    padding-bottom: 110px;
  }
  .athena_wanna .description .content {
    font-size: 34px;
  }
  .athena-ourfeatured .product .product-img {
    width: 100%;
    height: 290px;
  }
  .athena-ourfeatured .product .content {
    width: 80%;
  }
  .section-Contact {
    background: linear-gradient(
        90deg,
        rgba(18, 23, 40, 0.57) 47.4%,
        rgba(18, 23, 40, 0) 83.85%
      ),
      url("../assets/images/athena/bg_wanna-mobile.png"), #121728;
    background-repeat: no-repeat;
    background-size: cover;
  }
  // End wanna
  //Start GET IN
  .contact .contact-left {
    width: 100%;
  }
  .contact .contact-left .contact-left-title {
    font-size: 1.25rem;
  }
  .contact .contact-left .contact-title-left__subtitle {
    font-size: 2.1rem;
  }
  .contact .contact-left .contact-title-left__content {
    opacity: 0.87;
    font-weight: 400;
  }
  // .formSubmitting {
  //   // width: 58%;
  // }
  //END Get in
  // Start footer
  .footer {
    text-align: center;
    height: 100%;
    .logo {
      order: 1;
    }
    .menu {
      .nav {
        flex-direction: column;
      }
      order: 2;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }
    .copyright {
      text-align: center;
      order: 3;
    }
  }
  // End footer
  // END ATHENA
  //IMPRINT
  header.header-imprint {
    .header-imprint-content {
      position: relative;
      width: 100%;
      height: 100px;
      .header-imprint-circle {
        background-image: none;
      }
      // .header-imprint-circle {
      //   background-image: radial-gradient(
      //     circle 63px at 50% 0,
      //     transparent 700px,
      //     #0a0b18 50px
      //   );
      //   padding-bottom: 9rem;
      // }
    }
    .header-imprint-circle-background {
      height: 175px;
      width: 175px;
      top: 34px;
      left: calc(50% - 87px);
      .header-imprint-circle-background-image {
        width: 47px;
        height: 56px;
      }
    }
  }
  header.header-imprint {
    background-image: url(../assets/images/imprint/headerImprint-mobile.png);
    padding-top: 0;
    height: 480px;
    margin-top: 72px;
  }
  header.header-imprint .header-imprint-content .header-imprint-circle {
    bottom: -273px;
  }
  .imprint-content {
    margin-top: 64px;
    margin-bottom: 54px;
    .title {
      font-size: 34px;
      margin-bottom: 32px;
    }
    .image-mobile {
      display: inline;
    }
    .image-mobile img {
      width: 100%;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      justify-content: space-between;
      margin-top: 32px;
      height: auto;
      .pham {
        font-weight: 700;
        font-size: 20px;
        color: rgba(255, 255, 255, 0.87);
        line-height: 150%;
        width: 100%;
        // padding-right: 5rem;
        height: auto;
        span {
          display: inline-block;
        }
      }
      .am-falder {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.87);
        line-height: 24px;
        margin-top: 16px;
        height: auto;
        width: 130px;
        p {
          margin: 0;
        }
      }
      .content-1 {
        font-size: 16px;
        opacity: 0.87;
        width: 100%;
        line-height: 24px;
        margin-top: 16px;
        .down-text {
          display: block;
        }
        .change-color {
          font-weight: 700;
          color: #4fb978;
        }
      }
      .content-2 {
        font-size: 16px;
        opacity: 0.87;
        width: 100%;
        line-height: 24px;
        margin-top: 16px;
        .down-text {
          display: block;
        }
      }
    }
  }
  .imprint-image {
    margin-top: 110px;
    display: none;
  }
  .imprint-image-tablet {
    display: none;
  }
  //END IMPRINT
  .aboutus .aboutus_information {
    height: 540px;
  }
  .aboutus {
    .aboutus_sticky {
      position: inherit !important;
    }
  }
  // .homepage-page {
  //   display: none;
  // }
  // .mobile-vectorImage {
  //   display: block !important;
  // }
}

@media only screen and (width: 600px) {
  //HOME
  .nav-link {
    padding: 0.5rem 0.5rem;
  }
  .aboutus .aboutus__heading,
  .aboutus .aboutus__content {
    width: 55%;
  }
  .aboutus_image .aboutus_image__img {
    height: 25.5rem;
  }
  .aboutus_image .aboutus_image__svg {
    height: 15rem;
    width: 15rem;
    left: -3rem;
    bottom: 6rem;
  }
  .aboutus_image {
    margin-top: 15rem;
  }
  .character .description {
    margin-top: 5.375rem;
  }
  .video_header {
    margin-top: 72px;
  }
  .feedBack .feedBackRight__sticky {
    display: none;
  }
  .formSubmitting {
    width: 40%;
    margin-top: 16px;
  }
  //AND HOME
  .c-dark-theme .btn-success {
    border-top-width: 0rem;
    border-bottom-width: 0rem;
    padding: 12px 16px;
  }
  .contact .contact_btn__submit {
    font-size: 0.8rem;
  }
  // ABOUT-US
  .whatbai {
    padding-top: 4rem;
  }
  .whatbai .whatbai-quote {
    margin: 0 1rem;
  }
  .whatbai-ceo {
    padding: 0 1rem;
  }
  .whatbai .whatbai-ceo .whatbai-ceo-left {
    margin-right: 50px;
  }
  .whatbai .whatbai-ceo img {
    width: 250px;
    height: auto;
  }
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-left
    .whatbai-ceo-left-infomation
    .whatbai-ceo-left-infomation__name {
    font-size: 34px;
  }
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-left
    .whatbai-ceo-left-infomation
    .whatbai-ceo-left-infomation__position,
  .whatbai
    .whatbai-ceo
    .whatbai-ceo-left
    .whatbai-ceo-left-infomation
    .whatbai-ceo-left-infomation__content
    p {
    font-size: 1rem;
  }
  //ourvision
  .aboutus-activity .aboutus-activity__title {
    font-size: 34px;
  }
  .ourvision_part-right {
    margin-top: 5rem;
  }
  .ourvision .ourvision_tabpane {
    padding: 0;
  }
  .ourvision .ourvision-quote p {
    padding: 0 1rem;
  }
  .ourvision .ourvision-quote {
    margin: 0;
  }
  .ourvision .ourvision-questions__list .ourvision-questions__item {
    padding-left: 50px !important;
  }
  .ourvision .ourvision_tabpane .ourvision_tabpane-img-elip {
    top: -250px;
  }
  .ourvision .ourvision_tabpane .ourvision_tabpane-img-B {
    top: -200px;
  }
  .ourvision .ourvision_tabpane .ourvision_tabpane-content {
    font-size: 1rem;
    line-height: 1.5rem;
    overflow: scroll;
    height: 200px;
  }
  .about-us__tab-list {
    padding: 0 !important;
    justify-content: center;
  }
  .about-us__tab-list .about-us__tab-item .about-us__tab-item__link {
    padding: 8px 14px !important;
    font-size: 0.85rem !important;
  }
  //aboutus-activity
  .aboutus-activity .aboutus-activity__title {
    margin: 0;
  }
  .aboutus-activity .aboutus-activity__quote {
    margin: 1.1rem 0;
  }
  .aboutus-activity {
    padding: 4rem 0;
  }
  //tab-content
  .tab-content-left .tab-content-left-title,
  .tab-content-left .tab-content-left-quote,
  .tab-content-middle .tab-content-middle-title,
  .tab-content-middle .tab-content-middle-quote,
  .tab-content-right .tab-content-right-title,
  .tab-content-right .tab-content-right-quote {
    margin: 0;
  }
  .tab-content-right .tab-content-right-img-list,
  .tab-content-middle .tab-content-middle-img-list,
  .tab-content-left .tab-content-left-img-list {
    margin: 2rem 0 0 0;
  }
  .tab-content-left .tab-content-left-quote p,
  .tab-content-middle .tab-content-middle-quote p,
  .tab-content-right .tab-content-right-quote p {
    margin-top: 1.7rem;
    font-size: 1rem;
  }
  .item-img-left img {
    height: 100%;
  }
  .item-img-right {
    padding: 0 1rem 0 5px;
  }
  .icon-img-down-left {
    padding-top: 5px !important;
    padding-right: 10px;
  }
  .icon-img-down-right {
    padding-top: 5px !important;
    padding-left: 10px;
  }
  .Product-top .Product-top__title,
  .Product-bottom .Product-bottom__title {
    font-size: 24px;
    line-height: 150%;
  }
  .Product-top .Product-top__button,
  .Product-bottom .Product-bottom__button {
    padding: 12px;
  }
  // END ABOUT-US
  // START AI_DESIGN
  .information {
    margin: 0;
  }
  .section-idea1 .idea .idea-bottom .idea-bottom-image,
  .section-idea2 .idea .idea-bottom .idea-bottom-image,
  .section-idea3 .idea .idea-bottom .idea-bottom-image,
  .section-idea4 .idea .idea-bottom .idea-bottom-image,
  .section-idea5 .idea .idea-bottom .idea-bottom-image {
    width: 350px !important;
    height: 400px;
  }
  .idea .designai .designai-main .designai-index {
    line-height: 1.7rem;
    font-size: 1.2rem;
  }
  .idea .designai .designai-main .designai-title {
    line-height: 1.4rem;
    font-size: 1.2rem;
  }
  .idea .designai .designai-main .designai-content {
    font-size: 1rem;
    line-height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .idea .designai .designai-main {
    padding: 20px;
    height: 350px;
  }
  .idea .idea-top {
    height: 400px;
  }
  // END AI_DESIGN
  //START PRODUCT
  header.header-product {
    padding-top: 9.07rem;
    padding-bottom: 7.07rem;
    margin-top: 72px;
  }
  .subheader-product {
    margin: 0;
  }
  .magnificent-products .magnificent-products-title {
    margin: 0;
  }
  .magnificent-products-subcontent-list
    .magnificent-products-subcontennt-background-left {
    height: 246px !important;
  }
  //END PRODUCT
  //START ATHENA
  header.header-athena {
    margin-top: 72px;
    padding-top: 7.875rem;
    padding-bottom: 7rem;
  }
  .athena-content {
    padding: 66px 0px;
  }
  .product-img {
    background-size: cover;
    background-position: center center;
    width: 100%;
  }
  .athena-ourfeatured .product .product-img {
    width: 100%;
    border-radius: 20px;
  }
  .athena-ourfeatured .product .content .title-item {
    margin-right: 1rem;
  }
  .athena_wanna .description {
    margin: 0;
    padding: 8rem 0;
  }
  .athena_wanna {
    padding: 0;
  }
  .athena_wanna .description .content {
    width: 100%;
  }
  .section-Contact {
    background: linear-gradient(
        90deg,
        rgba(18, 23, 40, 0.57) 47.4%,
        rgba(18, 23, 40, 0) 83.85%
      ),
      url("../assets/images/athena/bg_wanna-mobile.png"), #121728;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .popup--Video {
    width: 95% !important;
    height: 566px;
    margin: 0 auto;
  }
  .popup-right .title {
    font-size: 24px;
    margin: 1rem 0;
  }
  .popup-right .content {
    overflow: scroll;
    height: 150px;
    margin: 0;
  }
  //END ATHENA
  //START CONTACT-US
  header.header-contact-us {
    margin-top: 72px;
    padding-top: 7.875rem;
    padding-bottom: 7rem;
  }
  .contact1 .formSubmitting {
    margin-top: 16px;
  }
  .contact1 .contact_btn__submit {
    font-size: 0.8rem;
  }
  //END CONTACT-US
  // IMPRINT
  .imprint-content {
    margin-bottom: 70px;
  }
  .imprint-image {
    display: none;
  }
  .imprint-image-tablet {
    display: block;
  }
  .imprint-image-tablet img {
    width: 100%;
    padding-top: 14rem;
  }
  .imprint-content .content .pham {
    width: 100%;
    height: auto;
  }
  .imprint-content .content .am-falder {
    height: auto;
  }
  .imprint-content .content .am-falder p {
    margin-top: 1rem;
  }
  .imprint-content .content .content-2 {
    margin-bottom: 1rem;
  }
  .imprint-content .content .content-1 {
    margin-bottom: 1rem;
    width: 500px;
  }
  //END IMPRINT
}

@media (max-width: 812px) {
  .popup-modal .popup-video {
    height: 183px !important;
  }
  .popup-modal .button-next {
    right: -9%;
  }
  .popup-modal .button-prev {
    left: -9%;
  }
  .popup-right .title {
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  header.header-policy .header-content {
    width: 305px !important;
  }
  header.header-policy .header-content .title-cookies-policy {
    font-size: 40px;
  }
  .popup-right .content {
    height: 150px !important;
  }
  .popup-right .title {
    font-size: 20px;
  }
  .popup--Video {
    height: 560px !important;
  }
}

@media (max-width: 768px) {
  .popup-right .content {
    height: 164px;
  }
}

@media (max-width: 736px) {
  .popup-right .content {
    height: 164px;
  }
  .modal__wrapper {
    width: 343px !important;
    .popup__button {
      margin-top: 2rem;
      .popup__button--mobile {
        width: 50% !important;
      }
    }
  }
}

// POLICY PAGE
.header-policy {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.07),
      rgba(255, 255, 255, 0.07)
    ),
    #050915;
  height: 272px;
}

// END POLICY PAGE
