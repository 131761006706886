// // Variable overrides
.c-dark-theme .c-footer {
    color: #e1e1e1;
    background: #050915;
    border-top: 0px solid #050915 !important;
}

.c-dark-theme .btn-success {
    color: #fff;
    background-color: #129F53;
    border-top-width: 1rem;
    border-bottom-width: 1rem;
    border-left-width: 2rem;
    border-right-width: 2rem;
    border-style: solid;
    border-color: #129F53 !important;
}

.c-dark-theme .btn-success:hover {
    color: #fff;
    background-color: #129F53 !important;
    border-color: #129F53 !important;
}

// .modal-content {
//     background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #050915 !important;
//     box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.3) !important;
//     flex-direction: row !important;
// }
.modal.show .modal-dialog {
    transform: none;
    margin-top: 22px;
}

.modal-backdrop.show {
    opacity: 0.9 !important;
}

// .modal-body {
//     padding: 2.8rem !important;
// }
.notification-success {
    background-color: #129F53 !important;
}

@media (max-width: 1024px) {
    .c-dark-theme .c-footer {
        height: auto;
        flex: 0 0 auto;
    }
}